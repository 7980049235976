import { useLayoutEffect } from "react"

// Not sure about this one. It repositions content and background image
export const useDisableDocumentScroll = () => {
  useLayoutEffect(() => {
    disableDocumentScroll()
    return enableDocumentScroll
  }, [])
}

const disableDocumentScroll = () => {
  document.body.style.overflowY = "hidden"
  // document.body.style.touchAction = "none"
}

const enableDocumentScroll = () => {
  document.body.style.overflowY = ""
  // document.body.style.touchAction = ""
}
