import { ReactNode } from "react"
import { modifiers } from "ui-tools"
import s from "./Card.module.scss"

type CardProps = {
  children?: ReactNode
  borderRadius?: "12" | "16" | "24" | "32"
}
export const Card = ({ children, borderRadius = "16" }: CardProps) => (
  <div className={modifiers(s, "card", `border-radius-${borderRadius}`)}>
    {children}
  </div>
)
