import { useStableCallback } from "hooks"
import { useEffect, useRef, useState } from "react"

type WhenVisibleProps = {
  whenVisible?: () => void
}
export const WhenVisible = ({ whenVisible }: WhenVisibleProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(true)

  const callback = useStableCallback(() => whenVisible?.())

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const cleanup = () => {
      observer.disconnect()
    }

    const observer = new IntersectionObserver((e) => {
      const isIntersecting = e.some((entry) => entry.isIntersecting)
      if (isIntersecting) {
        cleanup()
        callback()
        setIsVisible(false)
      }
    })

    observer.observe(element)

    return cleanup
  }, [callback])

  if (!isVisible) return null

  return (
    <div
      ref={ref}
      data-display-name="when-visible"
      style={{
        // iPhone's detection is flaky when there is no height. Add 1px to make it more stable.
        minHeight: "1px",
        minWidth: "1px",
        marginTop: "-1px",
      }}
    />
  )
}
