export const formatDate = (
  date: string | Date,
  locale: string,
  options: Intl.DateTimeFormatOptions
) => {
  if (locale === "en-US") {
    // We want to avoid AM/PM since we're using en-US in European countries where it's difficult to understand
    locale = "en-GB"
  }
  if (typeof date === "string") {
    date = new Date(date)
  }

  return new Intl.DateTimeFormat(locale, options).format(date)
}
