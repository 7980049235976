import { forwardRef, InputHTMLAttributes } from "react"
import s from "./Toggle.module.scss"

type ToggleProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
}
export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  ({ label, ...props }, ref) => (
    <label className={s.toggle}>
      <input type="checkbox" className={s.toggle__input} ref={ref} {...props} />

      <div className={s.toggle__circleContainer}>
        <div className={s.toggle__circle} />
      </div>

      {label && <div className={s.toggle__label}>{label}</div>}
    </label>
  )
)
