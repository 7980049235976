import * as authApiProd from "@attensi/auth"
import { mock } from "mock"

const notMockable = () => {
  throw new Error("Non mockable")
}

export const authApiMock = mock(authApiProd, (mocks) => ({
  getActiveSession: () => ({
    accessToken: "",
    companyNameKey: "test",
    logo: "",
    subdomain: "test",
    userId: 0,
    pickedLocale: "en-US",
    impersonation: false,
  }),

  getLinkWithActiveSession: () => "",
  getSessions: () => [mocks.getActiveSession()],
  getSubdomainFromPath: () => "",
  initializeAuth: (): undefined => undefined,
  logoutAndGetNext: () => mocks.getActiveSession(),

  redirectWithActiveSession: notMockable,
  loginNewSession: notMockable,
  logoutAndLogin: notMockable,
  refreshLogin: notMockable,
}))
