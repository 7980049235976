export const getTimeDifference = (startDate: Date, endDate: Date) => {
  const milliseconds = endDate.getTime() - startDate.getTime()
  const seconds = milliseconds / 1000
  const minutes = seconds / 60
  const hours = minutes / 60
  const days = hours / 24
  const weeks = days / 7
  const months = days / 30.4167
  const years = days / 365

  return {
    milliseconds: Math.floor(milliseconds),
    seconds: Math.floor(seconds),
    minutes: Math.floor(minutes),
    hours: Math.floor(hours),
    days: Math.floor(days),
    weeks: Math.floor(weeks),
    months: Math.floor(months),
    years: Math.floor(years),
  }
}
