import { useQueryAll } from "bonzai"
import { useToggle, useWindowWidth } from "hooks"
import { useTranslation } from "react-i18next"
import { custom, v1 } from "src/bonzai/bonzai"
import { formatNumber } from "src/dataUtilities/formatNumber"
import {
  getIsPlayable,
  getMaxStarsForProduct,
} from "src/dataUtilities/productDataUtilities"
import { usePickText } from "src/i18n/usePickText"
import { ProductModal } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"
import { ProductModalLoaderButtons } from "./ProductModalLoaderButtons"

type ProductModalMainLoaderProps = {
  productId: number
}
export const ProductModalLoader = (props: ProductModalMainLoaderProps) => (
  <QueryBoundary
    fallback={<ProductModal.Skeleton />}
    isSuspense={useWindowWidth().isBigWindow}
  >
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({ productId }: ProductModalMainLoaderProps) => {
  const pickText = usePickText()
  const { t, i18n } = useTranslation()
  const [product, status] = useData(productId)
  const { product_type, no_score, descriptions, titles, image } = product
  const [isReadMoreVisible, toggleReadMore] = useToggle(false)
  const isPlayable = getIsPlayable(product)

  return (
    <ProductModal
      description={pickText(descriptions, "")}
      image={image}
      productType={t(`product.PRODUCT_TYPES.${product_type}`)}
      score={formatNumber(i18n.language, status.high_score)}
      stars={status.stars}
      starsMax={getMaxStarsForProduct(product_type, !no_score)}
      starsRequirement={status.stars_requirement}
      title={pickText(titles)}
      isCertified={status.certified}
      isMandatory={status.is_mandatory}
      isProductTypeVisible={!isPlayable}
      isReadMoreVisible={isReadMoreVisible}
      isScoreVisible={isPlayable && status.high_score > 0}
      isStarsRequirementVisible={isPlayable && !status.certified}
      mandatoryText={t("user.MANDATORY")}
      readMoreText={t("product.PRODUCT_READ_MORE")}
      scoreText={t("product.SCORE")}
      toCompleteText={t("product.TO_COMPLETE")}
      toggleReadMore={toggleReadMore}
    >
      <ProductModalLoaderButtons product={product} />
    </ProductModal>
  )
}

const useData = (productId: number) => {
  return useQueryAll(() => [
    v1.getProduct.useQuery([productId]),
    custom.getProductProgress.useQuery([productId, { apiVersion: "v1" }]),
  ])
}
