import { exists } from "./exists"

export const combinePaths = (...args: (string | undefined)[]) => {
  const definedArgs = args
    .filter(exists)
    .map((arg, index) => (index > 0 ? trimFirstSlash(arg) : arg))

  if (definedArgs.length === 0) return ""

  return recursiveCombine(...definedArgs)
}

const recursiveCombine = (...[first, ...rest]: string[]): string => {
  if (rest.length === 0) return first!
  return appendSlash(first!) + recursiveCombine(...rest)
}

const trimFirstSlash = (text: string) => {
  return text.replace(/^\//, "")
}

const appendSlash = (text: string) => {
  if (text.endsWith("/")) return text
  return text + "/"
}
