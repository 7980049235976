import { custom, v1, v3 } from "./bonzai"

type Config = {
  mutate?: boolean // Send playthrough to api
  invalidate?: boolean // Trigger refetching of product status
  invalidateDependencies?: boolean // Trigger refetching of other endpoints dependent on playthroughs
  optimisticOneStar?: boolean // Give one star immediately
  optimisticOnFocus?: boolean // Wait with the star until window receives focus
}

export const createPlaythrough = async (
  productId: number,
  {
    mutate = true,
    invalidate = false,
    invalidateDependencies = false,
    optimisticOneStar = false,
    optimisticOnFocus = false,
  }: Config = {}
) => {
  const isNoScoreProduct = await getIsNoScore(productId)
  if (isNoScoreProduct) return

  if (optimisticOneStar) {
    if (optimisticOnFocus) {
      onFocusOnce(() => optimisticUpdate(productId))
    } else {
      optimisticUpdate(productId)
    }
  }

  if (mutate) {
    await apiUpdate(productId)
  }

  if (invalidateDependencies) {
    runDepenencyInvalidate()
  }

  if (invalidate) {
    runInvalidate(productId)
  }
}

const getIsNoScore = async (productId: number) => {
  const product = await v1.getProduct.fetchQueryOnce([productId])
  return product.no_score
}

const optimisticUpdate = (productId: number) => {
  custom.getProductProgress.setQueriesData([productId], (status) => {
    if (status.stars > 0) return

    incrementUserStatus(status.is_mandatory)

    status.high_score = 100
    status.certified = true
    status.stars = 1
  })
}

const incrementUserStatus = (isMandatory: boolean) => {
  v1.getUserStatus.setQueriesData((data) => {
    data.stars.count += 1
    data.certified.count += 1

    if (isMandatory) {
      data.mandatory.count += 1
    }
  })
}

const apiUpdate = async (productId: number) => {
  await v1.createPlaythrough(productId)
}

const runInvalidate = (productId: number) => {
  custom.getProductProgress.invalidateQueries([productId])
}

const runDepenencyInvalidate = () => {
  v3.getProductCategoryProgress.invalidateQueries() // Journey progress
}

const onFocusOnce = (handler: () => void) => {
  const onFocus = () => {
    window.removeEventListener("focus", onFocus)
    handler()
  }

  window.addEventListener("focus", onFocus)
}
