import { ImgHTMLAttributes } from "react"
import { optimizeImage } from "ui-tools"
import { exists } from "utils"
import { BREAKPOINTS } from "values"

type Breakpoint = `${"small" | "medium" | "big"}`
type Size = `${number}${"vw" | "px"}`
type EqualityOperator = "<" | "<=" | ">" | ">=" | "="
type ResponsiveSize = `(width ${EqualityOperator} ${Breakpoint}) ${Size}`
type Sizes =
  | Size
  | `${ResponsiveSize}, ${Size}`
  | `${ResponsiveSize}, ${ResponsiveSize}, ${Size}`

type ImageProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  "src" | "alt" | "srcSet" | "sizes"
> & {
  src: string
  alt: string

  /**
   * Array of image srcSet to pick from
   */
  srcSet: number[]

  /**
   * Rules used to pick size from `srcSet`.
   *
   * @example `500px`
   * @example `50vw`
   * @example `(width <= medium) 100vw, 50w`
   * @example `(width = medium) 50px, (width = big) 100px, 100vw`
   */
  sizes: Sizes
}

export const FiestaImage = ({
  src,
  alt,
  srcSet,
  sizes,
  ...props
}: ImageProps) => {
  return (
    <img
      alt={alt}
      srcSet={getSrcSet(src, srcSet)}
      sizes={getSizes(sizes)}
      src={src}
      {...props}
    />
  )
}

const getSrcSet = (src: string, srcSet: number[] | undefined) => {
  if (!srcSet) return undefined

  return srcSet
    .map((width) => `${optimizeImage(src, { width })} ${width}w`)
    .join(", ")
}

const getSizes = (sizes: string | undefined) => {
  if (!sizes) return undefined

  const { MEDIUM, SMALL } = BREAKPOINTS

  return sizes
    .replace("(width = small)", mediaQuery({ max: SMALL }))
    .replace("(width >= small)", mediaQuery({ min: 0 }))
    .replace("(width > small)", mediaQuery({ min: SMALL }))
    .replace("(width <= small)", mediaQuery({ max: SMALL }))
    .replace("(width < small)", mediaQuery({ max: 0 }))

    .replace("(width = medium)", mediaQuery({ min: SMALL, max: MEDIUM }))
    .replace("(width >= medium)", mediaQuery({ min: SMALL }))
    .replace("(width > medium)", mediaQuery({ min: MEDIUM }))
    .replace("(width <= medium)", mediaQuery({ max: MEDIUM }))
    .replace("(width < medium)", mediaQuery({ max: SMALL }))

    .replace("(width = big)", mediaQuery({ min: MEDIUM }))
    .replace("(width >= big)", mediaQuery({ min: MEDIUM }))
    .replace("(width > big)", mediaQuery({ max: 0 }))
    .replace("(width <= big)", mediaQuery({ min: 0 }))
    .replace("(width < big)", mediaQuery({ max: MEDIUM }))
}

const mediaQuery = ({ min, max }: { min?: number; max?: number } = {}) => {
  const minQuery = min ? `(min-width: ${min}px)` : undefined
  const maxQuery = max ? `(max-width: ${max - 1}px)` : undefined

  return [minQuery, maxQuery].filter(exists).join(" and ")
}
