import { useScrollToTopOnMount } from "hooks"
import { useTranslation } from "react-i18next"
import { v1 } from "src/bonzai/bonzai"
import { getNextPageParamV1 } from "src/bonzai/getNextPageParamV1"
import { JourneyLoader } from "src/components/JourneyLoader"
import { JourneyView, Loader } from "ui/exports/portal"
import { QueryBoundary, WhenVisible } from "utility-components"

type Category = v1["getProductCategories"]["data"][number]

export const JourneyViewLoader = () => (
  <QueryBoundary fallback={<Loader />}>
    <JourneyView>
      <Load />
    </JourneyView>
  </QueryBoundary>
)

const Load = () => {
  useScrollToTopOnMount()

  const { t } = useTranslation()
  const journeysData = useData()
  const { data, fetchNextPage, isFetching } = journeysData

  const isEmpty = data.pages[0]?.length === 0

  if (isEmpty) {
    return <JourneyView.Empty description={t("notFound.NOTFOUND_JOURNEY")} />
  }

  const pages = data.pages.map((categories, index) => (
    <JourneysPage
      key={index}
      journeys={categories}
      loadMore={fetchNextPage}
      isFirstPage={index === 0}
      isLastPage={index === data.pages.length - 1}
    />
  ))

  return (
    <>
      {pages}
      <Loader isVisible={isFetching} />
    </>
  )
}

type JourneysPageProps = {
  journeys: Category[]
  isFirstPage: boolean
  isLastPage: boolean
  loadMore: () => void
}
const JourneysPage = ({
  journeys,
  loadMore,
  isFirstPage,
  isLastPage,
}: JourneysPageProps) => {
  const journeyElements = journeys.map((journey, index) => (
    <JourneyLoader journey={journey} key={index} />
  ))

  return (
    <QueryBoundary isSuspense={!isFirstPage} fallback={<Loader />}>
      {journeyElements}
      {isLastPage && <WhenVisible whenVisible={loadMore} />}
    </QueryBoundary>
  )
}

const useData = () => {
  const { i18n } = useTranslation()

  return v1.getProductCategories.useInfiniteQuery(
    [{ journey: true, per_page: 3, locale: i18n.language }],
    {
      getNextPageParam: getNextPageParamV1,
      select: (res) => res.data.filter((category) => !category.palp_only),
    }
  )
}
