import { useTranslation } from "react-i18next"
import { v1, v3 } from "src/bonzai/bonzai"
import { TEXT_FALLBACK } from "src/constants"
import { JourneyTeaser } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

export const JourneyTeaserLoader = () => (
  <QueryBoundary fallback={<JourneyTeaser.Skeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const { journey, progress } = useFirstJourneyProgress()

  if (!journey) return null

  return (
    <JourneyTeaser
      link={{ to: "/journey" }}
      backgroundImage={journey.background_image_url ?? ""}
      title={journey.title ?? TEXT_FALLBACK} // TODO: use pickText with v3
      certified={progress?.certified_count ?? 0}
      certifiedMax={progress?.certified_max ?? 0}
      stars={progress?.stars_count ?? 0}
      starsMax={progress?.stars_max ?? 0}
    />
  )
}

const useFirstJourneyProgress = () => {
  const { i18n } = useTranslation()

  const journey = v1.getProductCategories.useQuery(
    [{ journey: true, per_page: 1, locale: i18n.language }],
    { select: (res) => res.data.find((category) => !category.palp_only) }
  )

  const progress = v3.getProductCategoryProgress.useQuery([journey?.id!], {
    enabled: journey !== undefined,
    suspense: false,
    select: (res) => res.data,
  })

  return { journey, progress }
}
