import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import featuredArrowIcon from "assets/icons/featuredArrowIcon.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { Children, PropsWithChildren, ReactNode, useState } from "react"
import { modifiers } from "ui-tools"
import { FiestaImage, FiestaLink, FiestaLinkProps } from "utility-components"
import { Card } from "../../base/Card"
import s from "./FeaturedProducts.module.scss"

export const FeaturedProducts = ({ children }: PropsWithChildren) => {
  const { numberOfProducts, index, paginateNext, paginateBack } =
    usePaginatedProducts(children)
  const xPosition = `${-index * 100}%`
  const transform = `translateX(${xPosition})`
  const showArrows = numberOfProducts > 1

  return (
    /*
      Using chromatic ignore since chromatic cannot handle featured products currently.
      TODO: Figure out why
    */
    <div className={s.featured} data-chromatic="ignore">
      <div className={s.featured__products} style={{ transform }}>
        {children}
      </div>
      {showArrows && <Arrow onClick={paginateBack} direction="left" />}
      {showArrows && <Arrow onClick={paginateNext} direction="right" />}
      <Progress current={index} length={numberOfProducts} />
    </div>
  )
}

type ArrowProps = {
  direction: "left" | "right"
  onClick: () => void
}
const Arrow = ({ direction, onClick }: ArrowProps) => (
  <button
    className={modifiers(s, "arrow", direction)}
    onClick={onClick}
    title={`Navigate ${direction}`}
  >
    <img src={featuredArrowIcon} alt="" />
  </button>
)

type ProgressProps = {
  current: number
  length: number
}
const Progress = ({ current, length }: ProgressProps) => {
  const progressItems = times(length, (index) => (
    <ProgressItem isActive={index === current} key={index} />
  ))

  return <div className={s.progress}>{progressItems}</div>
}

type ProgressItemProps = {
  isActive: boolean
}
const ProgressItem = ({ isActive }: ProgressItemProps) => (
  <div className={modifiers(s, "progressItem", { isActive })} />
)

type ProductProps = {
  link: FiestaLinkProps
  title: string
  score: string
  stars: number
  starsMax: number
  image: string

  isCertifiedVisible: boolean
  isMandatoryVisible: boolean
  isScoreVisible: boolean

  mandatoryText: string
  scoreText: string
  labelText: string
}

const Product = (props: ProductProps) => {
  const { link, isCertifiedVisible, isMandatoryVisible, isScoreVisible } = props

  return (
    <FiestaLink className={s.product} {...link}>
      <Image {...props} />
      <Badges>
        {isCertifiedVisible && <Certified />}
        {isMandatoryVisible && <Mandatory {...props} />}
      </Badges>
      <Gradient>
        <Label {...props} />
        <Title {...props} />
        {isScoreVisible && <Score {...props} />}
        <Stars {...props} />
      </Gradient>
    </FiestaLink>
  )
}

const Image = ({ image }: ProductProps) => (
  <FiestaImage
    className={s.image}
    src={image}
    alt=""
    srcSet={[650, 900, 1200]}
    sizes="(width = big) 650px, 100vw"
  />
)

const Badges = ({ children }: PropsWithChildren) => (
  <div className={s.product__badges}>{children}</div>
)

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="" />
)

const Mandatory = ({ mandatoryText }: ProductProps) => (
  <div className={s.mandatory}>{mandatoryText}</div>
)

const Gradient = ({ children }: PropsWithChildren) => (
  <div className={s.product__gradient}>{children}</div>
)

const Label = ({ labelText }: ProductProps) => (
  <div className={s.product__label}>{labelText}</div>
)

const Title = ({ title }: ProductProps) => (
  <div className={s.product__title}>{title}</div>
)

const Score = ({ score, scoreText }: ProductProps) => (
  <div className={s.product__score}>{`${score} ${scoreText}`}</div>
)

const Stars = ({ starsMax, stars }: ProductProps) => {
  const getSrc = (isFilled: boolean) => (isFilled ? starFilled : starEmpty)

  const starElements = times(starsMax, (index) => (
    <img
      className={s.stars__image}
      src={getSrc(index < stars)}
      alt=""
      key={index}
    />
  ))

  return <div className={s.stars}>{starElements}</div>
}

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton}>
      <div className={s.skeleton__featured} />
      <div className={s.skeleton__title} />
    </div>
  </Card>
)

FeaturedProducts.Product = Product
FeaturedProducts.Skeleton = Skeleton

const usePaginatedProducts = (children: ReactNode | undefined) => {
  const [index, setIndex] = useState(0)
  const childArray = Children.toArray(children)
  const numberOfProducts = childArray.length

  const paginateBack = () => {
    if (index === 0) {
      setIndex(numberOfProducts - 1)
    } else {
      setIndex(index - 1)
    }
  }

  const paginateNext = () => {
    if (index === numberOfProducts - 1) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }

  return { index, numberOfProducts, paginateBack, paginateNext }
}
