import dompurify from "dompurify"

type SanitizeVariant = "default" | "strict" | "allow-links"

export const sanitizeHtml = (
  value: string,
  variant: SanitizeVariant = "default"
) => {
  const sanitized = dompurify.sanitize(value, {
    ...getOptions(variant),
    RETURN_DOM: false,
    RETURN_DOM_FRAGMENT: false,
  })
  return { __html: sanitized }
}

/*
 * Options: https://github.com/cure53/DOMPurify#can-i-configure-dompurify
 */
const getOptions = (variant: SanitizeVariant): dompurify.Config => {
  switch (variant) {
    case "default":
      return {}
    case "allow-links":
      return { ALLOWED_ATTR: ["target", "rel", "href"] }
    case "strict":
      return { ALLOWED_TAGS: ["b", "i", "strong"] }
  }
}
