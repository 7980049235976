import i18next from "i18next"
import { loadLokaliseLocales } from "lokalise"

export const loadLocales = async (locales: string[]) => {
  const loadedLocales = await loadLokaliseLocales(locales)

  const DEEP = true
  const OVERWRITE = true

  for (const [locale, data] of Object.entries(loadedLocales)) {
    i18next.addResourceBundle(locale, "translation", data, DEEP, OVERWRITE)
  }

  i18next.emit("loadedResource")
}
