import produce from "immer"

type FnBase = (...args: any[]) => any
type FunctionsBase = Record<string, FnBase>
type FnReturn<Function extends FnBase> = Awaited<ReturnType<Function>>

type MockFn<Fn extends FnBase> = (
  ...args: Parameters<Fn>
) => Awaited<ReturnType<Fn>>

type Mocks<Functions extends FunctionsBase> = {
  [Key in keyof Functions]: MockFn<Functions[Key]>
}

type MockReturn<Functions> = {
  [Key in keyof Functions]: Functions[Key] extends FnBase
    ? MockFn<Functions[Key]>
    : never
}

type OverrideFn<Fn extends FnBase = FnBase> = (
  data: FnReturn<Fn>,
  args: Parameters<Fn>
) => FnReturn<Fn> | undefined | void

type Override<Functions extends FunctionsBase> = {
  [Key in keyof Functions]: Functions[Key] extends FnBase
    ? (fn: OverrideFn<Functions[Key]>) => void
    : never
}

type Mock<Functions extends FunctionsBase> = {
  mocks: Functions
  override: Override<Functions>
  resetOverrides: () => void
}

export const mock = <Functions extends FunctionsBase>(
  _: Functions,
  create: (mocks: Mocks<Functions>) => MockReturn<Functions>
): Mock<Functions> => {
  const { override, overrides, resetOverrides } = createOverrides()

  const mocks = {} as Mocks<Functions>
  const functions = create(mocks)

  for (const key in functions) {
    const fn = functions[key]

    mocks[key] = (...args) => {
      const res = fn(...args)

      const overrideFn = overrides.get(key)
      if (!overrideFn) return res

      return overrideFn(res, args)
    }
  }

  const asyncMocks = {} as any
  for (const key in mocks) {
    asyncMocks[key] = (...args: any) => {
      return Promise.resolve(mocks[key](...args))
    }
  }

  return {
    mocks: asyncMocks,
    resetOverrides,
    override,
  }
}

const createOverrides = () => {
  const overrides = new Map<string, OverrideFn>()

  const get = (_: any, functionName: string) => {
    return (overrideFn: OverrideFn) => {
      overrides.set(functionName, produce(overrideFn))
    }
  }

  const override = new Proxy({}, { get })

  const resetOverrides = () => {
    overrides.clear()
  }

  return {
    override,
    overrides,
    resetOverrides,
  }
}
