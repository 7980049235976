import { getActiveSession, refreshLogin } from "auth"
import { assert, Struct } from "superstruct"
import { blobToText, getQueryParams, waitMillis } from "utils"
import { FetchError, QueryParams, wrapFetch, WrapFetchProps } from "wrap-fetch"
import { getConfig } from "../apiConfig"
import { deserializeJsonApi } from "../utils/deserializeJsonApi"

const { delay } = getQueryParams()

export const get = <T>(
  schema: Struct<T>,
  path: string,
  params?: QueryParams
) => {
  return v3Fetch({
    method: "GET",
    path,
    params,
    transform: (response) => transformResponse(schema, response),
  })
}

export const post = <T>(schema: Struct<T>, path: string, attributes?: any) => {
  const data = { data: { attributes } }
  return v3Fetch({
    method: "POST",
    path,
    data,
    transform: (response) => transformResponse(schema, response),
  })
}

export const patch = <T>(schema: Struct<T>, path: string, attributes?: any) => {
  const data = { data: { attributes } }
  return v3Fetch({
    method: "PATCH",
    path,
    data,
    transform: (response) => transformResponse(schema, response),
  })
}

export const del = (path: string) => {
  return v3Fetch({
    method: "DELETE",
    path,
  })
}

export const getUserId = async () => {
  return (await getActiveSession()).userId
}

export const getCompanyNameKey = async () => {
  return (await getActiveSession()).companyNameKey
}

const v3Fetch = async <T>(
  options: Omit<WrapFetchProps<T>, "pathPrefix" | "headers" | "baseUrl">
) => {
  const { apiBaseUrl } = await getConfig()

  if (delay) {
    await waitMillis(Number(delay))
  }

  return wrapFetch({
    pathPrefix: "/api/v3",
    baseUrl: apiBaseUrl,
    onError: onError,
    headers: {
      accept: "application/vnd.api+json",
      ...(await getHeaders?.()),
    },
    ...options,
  })
}

const getHeaders = async () => {
  const { apiToken } = await getConfig()
  const { accessToken } = await getActiveSession()
  return {
    Authorization: "Bearer " + accessToken,
    "API-Token": apiToken,
  }
}

const onError = async (error: FetchError) => {
  if (error.status === 401) {
    await refreshLogin()
  }
}

const transformResponse = async <T>(schema: Struct<T>, response: Blob) => {
  const data = await JSON.parse(await blobToText(response))

  const deserialized = deserializeJsonApi(data)
  assert(deserialized, schema)
  return deserialized
}
