import addToCalendarIcon from "assets/icons/addToCalendar.svg"
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrowDown.svg"
import waitingList from "assets/icons/waitingList.svg"
import scaredBallonPerson from "assets/illustrations/scaredBallonPerson.svg"
import { PropsWithChildren, ReactNode } from "react"
import { modifiers } from "ui-tools"
import { ProductModalShell } from "../ProductModalShell"
import s from "./ProductModalEvent.module.scss"

export const ProductModalEvent = ({ children }: PropsWithChildren) => (
  <ProductModalShell.Side>
    <div className={s.modal}>{children}</div>
  </ProductModalShell.Side>
)

type HeaderProps = {
  text: string
  color?: "red"
}
const Header = ({ text, color }: HeaderProps) => (
  <div className={modifiers(s, "header", color)}>{text}</div>
)

type TicketProps = {
  isWaiting: boolean
  attendingText: string
  date: string
  time: string
}
const Ticket = ({ isWaiting, attendingText, date, time }: TicketProps) => (
  <div className={modifiers(s, "ticket", { isWaiting })}>
    <div className={s.ticket__attending}>{attendingText}</div>
    <div className={s.ticket__date}>{date}</div>
    <div className={s.ticket__time}>{time}</div>
  </div>
)

const DateButtons = ({ children }: PropsWithChildren) => (
  <div className={s.dateButtons}>{children}</div>
)

type DateButtonProps = {
  text: string
  color: "blue" | "white"
  onClick: () => void
}
const DateButton = ({ color, text, onClick }: DateButtonProps) => (
  <button className={modifiers(s, "dateButton", color)} onClick={onClick}>
    {text}
    <ArrowDownIcon />
  </button>
)

type AddToCalendarProps = {
  text: string
  onClick: () => void
}
const AddToCalendar = ({ text, onClick }: AddToCalendarProps) => (
  <button className={s.dateButtons__calendar} onClick={onClick}>
    <img src={addToCalendarIcon} alt="" />
    {text}
  </button>
)

const Dates = ({ children }: PropsWithChildren) => (
  <div className={s.dates}>{children}</div>
)

type DateProps = {
  month: string
  day: string
  time: string
  isActive: boolean
  isCancelled: boolean
  onClick: () => void
}
const Date = ({
  isActive,
  onClick,
  month,
  day,
  time,
  isCancelled,
}: DateProps) => (
  <button
    className={modifiers(s, "date", { isActive, isCancelled })}
    onClick={onClick}
    disabled={isCancelled}
  >
    <div className={s.date__month}>{month}</div>
    <div className={s.date__day}>{day}</div>
    <div className={s.date__time}>{time}</div>
  </button>
)

type WaitingInfoProps = {
  text: string
}
const WaitingInfo = ({ text }: WaitingInfoProps) => (
  <div className={s.waitingInfo}>
    <div className={s.waitingInfo__text}>{text}</div>
    <img className={s.waitingInfo__icon} src={waitingList} alt="" />
  </div>
)

type InfoListProps = {
  children: ReactNode
  isOpaque?: boolean
}
const InfoList = ({ isOpaque, children }: InfoListProps) => (
  <div className={modifiers(s, "infoList", { isOpaque })}>{children}</div>
)

type InfoProps = {
  icon: string
  label: string
  value: string
  isUrl?: boolean
  color?: "red"
}
const Info = ({ icon, label, value, isUrl, color }: InfoProps) => (
  <div className={modifiers(s, "info", color)}>
    <img className={s.info__icon} src={icon} alt="" />
    <div className={s.info__label}>{label}</div>
    <div className={s.info__value}>
      {isUrl ? (
        <a
          className={s.info__url}
          href={value}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      ) : (
        value
      )}
    </div>
  </div>
)

const Buttons = ({ children }: PropsWithChildren) => (
  <ProductModalShell.ActionButtons>
    <div className={s.buttons}>{children}</div>
  </ProductModalShell.ActionButtons>
)

type ButtonProps = {
  variant: "attend" | "unregister" | "waiting-list"
  onClick: () => void
  children: ReactNode
}
const Button = ({ variant, children, onClick }: ButtonProps) => (
  <button className={modifiers(s, "button", variant)} onClick={onClick}>
    {children}
  </button>
)

type EmptyProps = {
  header: string
}
const Empty = ({ header }: EmptyProps) => (
  <div className={s.empty}>
    <div className={s.empty__header}>{header}</div>
    <img className={s.empty__image} src={scaredBallonPerson} alt="" />
  </div>
)

ProductModalEvent.Header = Header
ProductModalEvent.Ticket = Ticket
ProductModalEvent.DateButtons = DateButtons
ProductModalEvent.DateButton = DateButton
ProductModalEvent.Dates = Dates
ProductModalEvent.AddToCalendar = AddToCalendar
ProductModalEvent.Date = Date
ProductModalEvent.WaitingInfo = WaitingInfo
ProductModalEvent.InfoList = InfoList
ProductModalEvent.Info = Info
ProductModalEvent.Buttons = Buttons
ProductModalEvent.Button = Button
ProductModalEvent.Empty = Empty
