import * as sentry from "sentry"
import {
  APP_ENV,
  IS_PROD_BUILD,
  SENTRY_URL,
  VERCEL_GIT_COMMIT_SHA,
} from "../env"
import { APP_TYPE, APP_VERSION, IS_APP } from "../vuplex/constants"

export const initializeSentry = async () => {
  const Sentry = await sentry.initializeSentry({
    isEnabled: IS_PROD_BUILD,
    sentryUrl: SENTRY_URL,
    environment: APP_ENV,
    release: VERCEL_GIT_COMMIT_SHA,
  })

  Sentry.setContext("App", {
    "Is in app mode?": IS_APP,
    "App type": APP_TYPE,
    "App version": APP_VERSION,
  })
}
