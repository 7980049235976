import { v1 } from "src/bonzai/bonzai"
import { pickTextAsync } from "src/i18n/usePickText"
import { sendVuplexMessage } from "./sendVuplexMessage"

type Product = v1["getProduct"]

export const sendOpenProductMessage = async (
  product: Product,
  href: string
) => {
  sendVuplexMessage({
    type: "OPEN_PRODUCT",
    payload: {
      id: product.id,
      companyId: product.company_id,
      type: product.product_type,
      title: await pickTextAsync(product.titles),
      description: await pickTextAsync(product.descriptions, ""),
      image: product.image,
      link: href,
      googlePlayLink: product.google_play_link,
      appStoreLink: product.apple_store_link,
      openExternalBrowser: product.properties.open_external_browser === "true",
    },
  })
}
