import * as authApiProd from "@attensi/auth"
import { authApiMock } from "./mock"

export const {
  getActiveSession,
  getLinkWithActiveSession,
  getSessions,
  getSubdomainFromPath,
  initializeAuth,
  loginNewSession,
  logoutAndGetNext,
  logoutAndLogin,
  redirectWithActiveSession,
  refreshLogin,
} = import.meta.env.MODE === "test" ? authApiMock.mocks : authApiProd
