import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { modifiers } from "ui-tools"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { ProductCard } from "../ProductCard/ProductCard"
import s from "./ProductCardEvent.module.scss"

type Props = {
  link: FiestaLinkProps
  info: string
  title: string
  month: string
  day: string
  stars: number
  starsMax: number

  isCertified: boolean
  isMandatory: boolean
  isAttending: boolean
  isFullyBooked: boolean
  isEmpty: boolean

  mandatoryText: string
}

export const ProductCardEvent = (props: Props) => {
  const { link, isMandatory, isCertified } = props

  return (
    <ProductCard.Shell>
      <FiestaLink className={s.card} {...link}>
        {isCertified && <Certified />}
        {isMandatory && <Mandatory {...props} />}
        <Info {...props} />
        <Date {...props} />
        <Title {...props} />
        <Stars {...props} />
      </FiestaLink>
    </ProductCard.Shell>
  )
}

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="" />
)

const Mandatory = ({ mandatoryText }: Props) => (
  <div className={s.mandatory}>{mandatoryText}</div>
)

const Info = ({
  info,
  isCertified,
  isAttending,
  isFullyBooked,
  isEmpty,
}: Props) => (
  <div
    className={modifiers(s, "info", {
      isCertified,
      isAttending,
      isFullyBooked,
      isEmpty,
    })}
  >
    {info}
  </div>
)

const Date = ({ isEmpty, month, day }: Props) => {
  if (isEmpty) return null

  return (
    <div className={s.date}>
      <div className={s.date__month}>{month}</div>
      <div className={s.date__day}>{day}</div>
    </div>
  )
}

const Title = ({ title }: Props) => <div className={s.title}>{title}</div>

type StarsProps = {
  stars: number
  starsMax: number
}
const Stars = ({ starsMax, stars }: StarsProps) => {
  const getSrc = (isFilled: boolean) => (isFilled ? starFilled : starEmpty)

  const starElements = times(starsMax, (index) => (
    <img
      className={s.stars__image}
      src={getSrc(index < stars)}
      alt=""
      key={index}
    />
  ))

  return <div className={s.stars}>{starElements}</div>
}
