type PaginatedPage = {
  pagination: {
    count: number
    next: number | null
  }
}

export const getNextPageParamV1 = (lastPage: PaginatedPage) => {
  const { count, next } = lastPage.pagination
  if (count === 0 || next === null) return
  return [{ page: next }] as const
}
