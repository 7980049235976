import { useTranslation } from "react-i18next"

export const useGetOrdinal = () => {
  const { t, i18n } = useTranslation()
  const rules = new Intl.PluralRules(i18n.language, { type: "ordinal" })

  const getOrdinal = (rule: Intl.LDMLPluralRule) => {
    if (rule === "one") return t("ordinal.ONE")
    if (rule === "two") return t("ordinal.TWO")
    if (rule === "few") return t("ordinal.FEW")
    return t("ordinal.OTHER")
  }

  return (value: number) => {
    const rule = rules.select(value)
    return value + getOrdinal(rule)
  }
}
