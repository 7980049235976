import { blobToText, combinePaths, waitMillis, withQueryParams } from "utils"
import { FetchError } from "./FetchError"
import { Headers, QueryParams } from "./types"

export type WrapFetchProps<T> = {
  path: string
  pathPrefix?: string
  baseUrl?: string
  method?: "GET" | "POST" | "DELETE" | "PATCH"
  headers?: Headers
  data?: unknown
  params?: QueryParams
  onError?: (error: FetchError) => any
  transform?: (responseBody: Blob) => Promise<T> | T
  delay?: string | number
}

export const wrapFetch = async <T = unknown>(
  args: WrapFetchProps<T>
): Promise<T> => {
  const {
    path,
    pathPrefix,
    baseUrl,
    data,
    params,
    onError,
    method = "GET",
    transform,
    delay,
  } = args

  if (delay) {
    await waitMillis(Number(delay))
  }

  let url = combinePaths(baseUrl, pathPrefix, path)
  if (params) url = withQueryParams(url, params)

  let responseStatus: number | undefined
  let responseBlob: Blob | undefined
  let isRequestComplete = false

  try {
    const req = new Request(url, {
      method,
      body: data ? JSON.stringify(data) : undefined,
      headers: await mergeHeaders(args),
    })

    const response = await fetch(req)
    responseStatus = response.status

    responseBlob = await response.blob()

    isRequestComplete = true

    if (!response.ok) {
      throw response
    }

    if (transform) {
      return await transform(responseBlob)
    }

    return undefined as T
  } catch (error) {
    // Ignore errors when reading text from blob. It's not important
    const responseText = responseBlob
      ? await blobToText(responseBlob).catch(() => undefined)
      : undefined

    const resolved = resolveError({
      error,
      method,
      url,
      responseText,
      responseStatus,
      isRequestComplete,
    })
    await onError?.(resolved)
    throw resolved
  }
}

const mergeHeaders = async <T>(args: WrapFetchProps<T>) => {
  return {
    ...(args.data ? { "Content-Type": "application/json" } : undefined),
    ...args.headers,
  }
}

type ResolveErrorProps = {
  error: unknown
  method: string
  url: string
  responseStatus: number | undefined
  responseText: string | undefined
  isRequestComplete: boolean
}

const resolveError = ({
  error,
  responseStatus,
  responseText,
  url,
  method,
  isRequestComplete,
}: ResolveErrorProps): FetchError => {
  if (error instanceof FetchError) {
    return error
  }

  if (error instanceof Response) {
    const message = responseStatus
      ? "Request failed with status code " + responseStatus
      : "Request failed without status code"

    return new FetchError({
      url,
      method,
      message,
      text: responseText,
      status: responseStatus,
      cause: undefined,
      isRequestComplete,
    })
  }

  if (error instanceof Error) {
    return new FetchError({
      url,
      method,
      message: error.message,
      cause: error,
      status: responseStatus,
      text: responseText,
      isRequestComplete,
    })
  }

  throw new Error(`${error}`, { cause: error })
}
