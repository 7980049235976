import { unknown } from "superstruct"
import {
  DeleteNewsPostReactionParams,
  GetGroupParams,
  GetKpiSalesParams,
  GetNewsPostParams,
  GetNewsPostsParams,
  GetProductCourseParams,
  GetProductParams,
  GetProductsParams,
  UpdateActivityItemParams,
} from "./v3Params"
import {
  ActivityItemsResponse,
  ActivityProgressResponse,
  CompanyResponse,
  CompanySettingsResponse,
  CreateNewsPostReactionResponse,
  DepartmentsResponse,
  GroupResponse,
  GroupUsersResponse,
  KpiSalesResponse,
  NewsPostResponse,
  NewsPostsResponse,
  ProductCategoryProgressResponse,
  ProductCourseResponse,
  ProductProgressResponse,
  ProductResponse,
  ProductsResponse,
  UpdateActivityItemResponse,
  UserProductResponse,
  UserResponse,
} from "./v3Responses"
import { del, get, getCompanyNameKey, getUserId, post } from "./v3Utils"

export const getCurrentUser = async () => {
  return get(UserResponse, `/users/current`)
}

export const getCurrentCompany = async () => {
  const companyName = await getCompanyNameKey()
  return get(CompanyResponse, `/companies/${companyName}`)
}

export const getCurrentCompanySettings = async () => {
  const companyName = await getCompanyNameKey()
  return get(CompanySettingsResponse, `/companies/${companyName}/settings`)
}

export const getNewsPosts = async (params: GetNewsPostsParams = {}) => {
  const companyName = await getCompanyNameKey()
  const sort = "-published_at"
  const withInclude = { include: "image,reactions", sort, ...params }
  return get(NewsPostsResponse, `/companies/${companyName}/posts`, withInclude)
}

export const getNewsPost = (
  newsPostId: string,
  params: GetNewsPostParams = {}
) => {
  const include = ["image", ...(params.include ?? [])].join(",")
  const merged = { ...params, include }
  return get(NewsPostResponse, `/posts/${newsPostId}`, merged)
}

export const createNewsPostReaction = (newsPostId: string) => {
  const data = { reaction_type: "like" }
  const path = `/posts/${newsPostId}/reactions`
  return post(CreateNewsPostReactionResponse, path, data)
}

export const deleteNewsPostReaction = ({
  news_post_id,
  reaction_id,
}: DeleteNewsPostReactionParams) => {
  return del(`/posts/${news_post_id}/reactions/${reaction_id}`)
}

export const getActivityItems = (productId: number) => {
  return get(ActivityItemsResponse, `products/${productId}/activity_items`)
}

export const getActivityProgress = async (productId: number) => {
  const user_id = await getUserId()
  const url = `users/${user_id}/products/${productId}/activity_progress`
  return get(ActivityProgressResponse, url)
}

export const getCurrentUserProduct = async (productId: number) => {
  const user_id = await getUserId()
  const url = `users/${user_id}/products/${productId}`
  return get(UserProductResponse, url)
}

export const updateActivityItem = async ({
  product_id,
  ...params
}: UpdateActivityItemParams) => {
  const user_id = await getUserId()
  const url = `products/${product_id}/activity_item_users`
  return post(UpdateActivityItemResponse, url, { user_id, ...params })
}

export const getProduct = (
  productId: number,
  { include }: GetProductParams = {}
) => {
  const params = { include: include?.join(",") }
  return get(ProductResponse, `products/${productId}`, params)
}

export const getProductProgress = async (productId: number) => {
  const user_id = await getUserId()
  const url = `users/${user_id}/products/${productId}/progress`
  return get(ProductProgressResponse, url)
}

export const getProductCourse = (
  course_id: string,
  { include }: GetProductCourseParams = {}
) => {
  const params = { include: include?.join(",") }
  return get(ProductCourseResponse, `courses/${course_id}`, params)
}

export const getGroup = (
  group_id: string,
  { include }: GetGroupParams = {}
) => {
  const params = { include: include?.join(",") }
  return get(GroupResponse, `groups/${group_id}`, params)
}

export const getGroupUsers = (group_id: string) => {
  return get(GroupUsersResponse, `groups/${group_id}/users`)
}

export const addMyUserToGroup = async (group_id: string) => {
  const userId = await getUserId()
  return post(unknown(), `users/${userId}/groups`, { group_id })
}

export const removeMyUserFromGroup = async (group_id: string) => {
  const userId = await getUserId()
  return del(`users/${userId}/groups/${group_id}`)
}

export const getProductCategoryProgress = async (categoryId: number) => {
  const userId = await getUserId()
  const path = `users/${userId}/product_categories/${categoryId}/progress`
  return get(ProductCategoryProgressResponse, path)
}

export const getMyKpiSales = async (params: GetKpiSalesParams) => {
  const userId = await getUserId()
  const path = `users/${userId}/kpi_sales`
  return get(KpiSalesResponse, path, params)
}

export const getDepartmentKpiSales = async (
  departmentId: string,
  params: GetKpiSalesParams
) => {
  const path = `groups/${departmentId}/kpi_sales`
  return get(KpiSalesResponse, path, params)
}

export const getUserDepartments = async () => {
  const userId = await getUserId()
  return get(DepartmentsResponse, `users/${userId}/departments`)
}

export const getProducts = async (params: GetProductsParams) => {
  const userId = await getUserId()
  return get(ProductsResponse, `users/${userId}/products`, params)
}
