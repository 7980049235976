import { capitalize, maxBy } from "lodash-es"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { custom, v3 } from "src/bonzai/bonzai"
import { ConfigError } from "src/tools/ConfigError"
import { Dropdown, KpiSalesWidget } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type KpiSalesData = Exclude<v3["getMyKpiSales"]["data"], null>
type SalesScope = custom["getSaleScopes"][number]

const ENTRIES_PER_PAGE = 5

export const KpiSalesWidgetLoader = () => (
  <QueryBoundary fallback={<KpiSalesWidget.Skeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const { t } = useTranslation()
  const { scopes, selectedScope, setSelectedScope } = useSalesScopes()

  const sales = custom.getKpiSales.useQuery([selectedScope])

  if (!sales) {
    return null
  }

  const { months, current_month_count, year_count } = sales

  return (
    <KpiSalesWidget>
      <ScopeSelect
        scopes={scopes}
        selectedScope={selectedScope}
        onScopeChange={setSelectedScope}
      />
      <Chart months={months} />
      <KpiSalesWidget.Summary>
        <KpiSalesWidget.SummaryItem
          label={t("kpi.THIS_YEAR")}
          value={year_count}
        />
        <KpiSalesWidget.SummaryItem
          label={t("kpi.THIS_MONTH")}
          value={current_month_count.value}
        />
      </KpiSalesWidget.Summary>
    </KpiSalesWidget>
  )
}

type ScopeSelectProps = {
  scopes: SalesScope[]
  selectedScope: SalesScope
  onScopeChange: (value: SalesScope) => void
}
const ScopeSelect = ({
  scopes,
  selectedScope,
  onScopeChange,
}: ScopeSelectProps) => {
  const options = scopes.map((scope) => (
    <Dropdown.Option key={scope.type} value={scope}>
      {scope.label}
    </Dropdown.Option>
  ))

  return (
    <Dropdown value={selectedScope} onChange={onScopeChange}>
      <Dropdown.CustomButton>
        <KpiSalesWidget.Header>
          <KpiSalesWidget.Title text={selectedScope.label} />
          <Dropdown.ChevronIcon />
        </KpiSalesWidget.Header>
      </Dropdown.CustomButton>
      <Dropdown.Options>{options}</Dropdown.Options>
    </Dropdown>
  )
}

type ChartProps = {
  months: KpiSalesData["months"]
}
const Chart = ({ months }: ChartProps) => {
  const { i18n } = useTranslation()

  const {
    maxValue,
    visibleMonths,
    canPaginateLeft,
    canPaginateRight,
    paginateLeft,
    paginateRight,
  } = usePaginatedMonths(months)

  const entries = visibleMonths.map((month, index) => (
    <KpiSalesWidget.Entry
      key={visibleMonths.length - index}
      month={formatMonth(i18n.language, month.timestamp)}
      value={month.value}
      maxValue={maxValue}
    />
  ))

  return (
    <KpiSalesWidget.Chart>
      <KpiSalesWidget.PaginationArrow
        direction="left"
        isDisabled={canPaginateLeft}
        onClick={paginateLeft}
      />
      <KpiSalesWidget.Entries>{entries}</KpiSalesWidget.Entries>
      <KpiSalesWidget.PaginationArrow
        direction="right"
        isDisabled={canPaginateRight}
        onClick={paginateRight}
      />
    </KpiSalesWidget.Chart>
  )
}

const usePaginatedMonths = (months: KpiSalesData["months"]) => {
  const [page, setPage] = useState(1)

  const startIndex = months.length - ENTRIES_PER_PAGE * page
  const endIndex = startIndex + ENTRIES_PER_PAGE
  const visibleMonths = months.slice(Math.max(0, startIndex), endIndex)
  const maxValue = maxBy(visibleMonths, (month) => month.value)?.value ?? 0

  const canPaginateLeft = startIndex <= 0
  const canPaginateRight = endIndex >= months.length

  const paginateLeft = () => setPage((page) => page + 1)
  const paginateRight = () => setPage((page) => page - 1)

  return {
    maxValue,
    visibleMonths,
    canPaginateLeft,
    canPaginateRight,
    paginateLeft,
    paginateRight,
  }
}

const useSalesScopes = () => {
  const scopes = custom.getSaleScopes.useQuery()
  const firstScope = scopes[0]

  if (firstScope === undefined) {
    throw new ConfigError("No sale scopes")
  }

  const [selectedScope, setSelectedScope] = useState(firstScope)
  return { scopes, selectedScope, setSelectedScope }
}

const formatMonth = (locale: string, timestamp: string) => {
  const date = new Date(timestamp)
  const month = new Intl.DateTimeFormat(locale, {
    month: "short",
  }).format(date)
  return capitalize(month)
}
