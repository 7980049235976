import { useMatch } from "@tanstack/react-location"

export const useRouteParamNumber = (paramName: string) => {
  const paramValue = useMatch().params[paramName]
  const coercedToNumber = Number(paramValue)
  if (Number.isNaN(coercedToNumber)) {
    throw new TypeError(
      `Route param ${paramName} is not a valid number ${paramValue}`
    )
  }
  return coercedToNumber
}
