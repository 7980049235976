import { FetchQueryOptions } from "@tanstack/react-query"
import { FetchQuery } from "./fetchQuery"
import { queryClient } from "./queryClient"
import { FnBase } from "./types"
import { getArgs } from "./utils/getArgs"

export const getFetchQueryOnce = (
  fn: FnBase,
  path: string[]
): FetchQuery<FnBase> => {
  return (...input) => {
    const { args = [], rest } = getArgs(input)
    const key = [...path, ...args]
    const [options] = rest
    const withDefaults: FetchQueryOptions = {
      staleTime: Infinity,
      retry: false,
      ...options,
    }
    return queryClient.fetchQuery(key, () => fn(...args), withDefaults)
  }
}
