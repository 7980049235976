import { FetchQueryOptions } from "@tanstack/react-query"
import { queryClient } from "./queryClient"
import { FnBase, FnParams, FnReturn, NoArgFn } from "./types"
import { getArgs } from "./utils/getArgs"

type Options<Fn extends FnBase> = FetchQueryOptions<FnReturn<Fn>>
type Return = Promise<void>

export type PrefetchQuery<Fn extends FnBase> = Fn extends NoArgFn
  ? {
      (args?: FnParams<Fn>, options?: Options<Fn>): Return
      (options?: Options<Fn>): Return
    }
  : {
      (args: FnParams<Fn>, options?: Options<Fn>): Return
    }

export const getPrefetchQuery = (
  fn: FnBase,
  path: string[]
): PrefetchQuery<FnBase> => {
  return (...input) => {
    const { args, rest } = getArgs(input)
    const key = [...path, ...args]
    return queryClient.prefetchQuery(key, () => fn(...args), ...rest)
  }
}
