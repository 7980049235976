import { useCallback, useRef } from "react"

type Handler = (...args: any[]) => void

/*
  Returns a referencially stable function that always calls the newest handler.
*/
export const useStableCallback = <T extends Handler>(
  handler: T
): ((...args: Parameters<T>) => void) => {
  const handlerRef = useRef<T>(handler)

  handlerRef.current = handler

  return useCallback((...args) => {
    handlerRef.current(...args)
  }, [])
}
