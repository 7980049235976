export const downloadBlob = (data: Blob, fileName: string) => {
  const url = URL.createObjectURL(data)

  const anchor = document.createElement("a")
  anchor.href = url
  anchor.download = fileName
  anchor.click()

  URL.revokeObjectURL(url)
}
