import { getTimeDifference } from "./getTimeDifference"

type GetElapsedTimeProps = {
  locale: string | undefined
  date: string | Date
}
export const getElapsedTime = ({ date, locale }: GetElapsedTimeProps) => {
  if (typeof date === "string") {
    date = new Date(date)
  }

  const { seconds, days, hours, minutes, months, weeks, years } =
    getTimeDifference(date, new Date())

  const rtf = new Intl.RelativeTimeFormat(locale)

  if (years > 0) return rtf.format(-years, "years")
  if (months > 0) return rtf.format(-months, "months")
  if (weeks > 0) return rtf.format(-weeks, "weeks")
  if (days > 0) return rtf.format(-days, "days")
  if (hours > 0) return rtf.format(-hours, "hours")
  if (minutes > 0) return rtf.format(-minutes, "minutes")
  return rtf.format(-seconds, "seconds")
}
