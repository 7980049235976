import { Outlet, Route } from "@tanstack/react-location"
import { WidgetLayoutLoader } from "src/components/WidgetLayoutLoader"
import { ExploreViewLoader } from "./components/ExploreViewLoader"
import { ForYouViewLoader } from "./components/ForYouViewLoader"
import { FrontPageLoader } from "./components/FrontPageLoader"
import { JourneyViewLoader } from "./components/JourneyViewLoader"
import { KpiSalesModalLoader } from "./components/KpiSalesModalLoader"
import { LeaderboardViewResponsive } from "./components/LeaderboardViewResponsive"
import { NewsItemViewLoader } from "./components/NewsItemViewLoader"
import { NewsViewLoader } from "./components/NewsViewLoader"
import { NotFoundPage } from "./components/NotFoundPage"
import { PdfModalLoader } from "./components/PdfModalLoader"
import { ProductModalShellLoader } from "./components/ProductModalShellLoader"
import { UserSettingsViewLoader } from "./components/UserSettingsViewLoader"
import { VideoModalLoader } from "./components/VideoModalLoader"
import { VppModalLoader } from "./components/VppModalLoader"
import { WebGamesModalLoader } from "./components/WebGamesModalLoader"
import { WebGLModalLoader } from "./components/WebGLModalLoader"

export const getRoutes = (): Route[] => [
  {
    path: "/",
    element: <FrontPageLoader />,
  },

  {
    path: "/for-you",
    element: (
      <WidgetLayoutLoader>
        <ForYouViewLoader />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes("/for-you"),
  },

  {
    path: "/journey",
    element: (
      <WidgetLayoutLoader>
        <JourneyViewLoader />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes("/journey"),
  },

  {
    path: "/explore",
    element: (
      <WidgetLayoutLoader>
        <ExploreViewLoader />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes("/explore"),
  },

  {
    path: "/leaderboards",
    element: (
      <WidgetLayoutLoader showUserInfoHeader={false}>
        <LeaderboardViewResponsive />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes("/leaderboards"),
  },

  {
    path: "/news/post/:newsPostId",
    element: <NewsItemViewLoader />,
  },

  {
    path: "/news",
    element: (
      <WidgetLayoutLoader showUserInfoHeader={false}>
        <NewsViewLoader />
        <Outlet />
      </WidgetLayoutLoader>
    ),
    children: getChildRoutes("/news"),
  },

  {
    path: "/me",
    element: <UserSettingsViewLoader />,
  },

  {
    element: <NotFoundPage />,
  },
]

const getChildRoutes = (closeRoute: string): Route[] => [
  {
    path: "/product/:productId/video",
    element: <VideoModalLoader closeRoute={closeRoute} />,
  },

  {
    path: "/product/:productId/pdf",
    element: <PdfModalLoader closeRoute={closeRoute} />,
  },

  {
    path: "/product/:productId/web-games",
    element: <WebGamesModalLoader closeRoute={closeRoute} />,
  },

  {
    path: "/product/:productId",
    element: <ProductModalShellLoader closeRoute={closeRoute} />,
  },

  {
    path: "/sim/:productId/play",
    element: <WebGLModalLoader closeRoute={closeRoute} />,
  },

  {
    path: "/sim/:productId",
    element: (
      <>
        <ProductModalShellLoader
          closeRoute={closeRoute}
          sideContent="leaderboard"
        />
        <Outlet />
      </>
    ),
    children: [
      {
        path: "vpp",
        element: async ({ params }) => (
          <VppModalLoader
            closeRoute={`${closeRoute}/sim/${params.productId}`}
          />
        ),
      },
    ],
  },

  {
    path: "/event/:productId",
    element: (
      <ProductModalShellLoader closeRoute={closeRoute} sideContent="event" />
    ),
  },

  {
    path: "/activity/:productId",
    element: (
      <ProductModalShellLoader closeRoute={closeRoute} sideContent="activity" />
    ),
  },

  {
    path: "/kpi-sales",
    element: <KpiSalesModalLoader closeRoute={closeRoute} />,
  },
]
