import { useQueryAll } from "bonzai"
import { PropsWithChildren } from "react"
import { custom, v1, v3 } from "src/bonzai/bonzai"
import { getFullName } from "src/dataUtilities/getFullName"
import { AppShell } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"
import { AppLauncherBtn } from "./AppLauncherBtn"
import { HeaderMenuLoader } from "./HeaderMenuLoader"
import { NavigationLoader } from "./NavigationLoader"
import { ProductPlayingLoader } from "./ProductPlayingLoader"

export const AppShellLoader = ({ children }: PropsWithChildren) => {
  const [flags, companySettings] = useData()

  const backgroundImage = flags?.enable_portal_background
    ? companySettings?.data.portal_background_url
    : undefined

  return (
    <AppShell backgroundImage={backgroundImage}>
      <QueryBoundary fallback={<AppShell.HeaderSkeleton />}>
        <Header />
      </QueryBoundary>
      <AppShell.Main>
        <QueryBoundary>{children}</QueryBoundary>
      </AppShell.Main>
      <AppShell.Footer />
      <QueryBoundary>
        <AppShell.BottomBar>
          <ProductPlayingLoader />
          <AppShell.BottomNavigation>
            <NavigationLoader />
          </AppShell.BottomNavigation>
        </AppShell.BottomBar>
      </QueryBoundary>
    </AppShell>
  )
}

const Header = () => {
  const [currentCompany, me] = useHeaderData()

  return (
    <>
      <AppShell.Header
        appLauncherButton={<AppLauncherBtn />}
        menu={<HeaderMenuLoader />}
        navigation={<NavigationLoader />}
        companyLogo={currentCompany.logo}
        avatarLink={{ to: "/me" }}
        userName={getFullName(me)}
        avatar={me.image}
      />
    </>
  )
}

const useData = () => {
  return useQueryAll(() => [
    custom.getPortalConfigurations.useQuery({
      suspense: false, // Don't block rendering
    }),

    v3.getCurrentCompanySettings.useQuery({
      suspense: false, // Don't block rendering
    }),
  ])
}

const useHeaderData = () => {
  return useQueryAll(() => [
    v1.getCurrentCompany.useQuery(),
    v1.getMe.useQuery(),
  ])
}
