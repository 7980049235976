import { t } from "i18next"
import { v1, v3 } from "src/bonzai/bonzai"

const MONTHS = 13

type SalesScope =
  | {
      type: "me"
      label: string
    }
  | {
      type: "department"
      id: string
      label: string
    }

export const getSaleScopes = async (): Promise<SalesScope[]> => {
  const myDepartment = await getMyDepartment()
  const scopeMe: SalesScope = {
    type: "me",
    label: t("kpi.YOUR_SALES"),
  }
  if (!myDepartment) return [scopeMe]
  return [
    scopeMe,
    { type: "department", id: myDepartment.id, label: myDepartment.name },
  ]
}

export const getKpiSales = async (selectedScope: SalesScope) => {
  if (selectedScope.type === "me") return await getMySales()
  return await getDepartmentSales(selectedScope.id)
}

const getMySales = async () => {
  const { data } = await v3.getMyKpiSales({ months: MONTHS })
  return data
}

const getDepartmentSales = async (departmentId: string) => {
  const { data } = await v3.getDepartmentKpiSales(departmentId, {
    months: MONTHS,
  })
  return data
}

const getMyDepartment = async () => {
  const [me, departments] = await Promise.all([
    v1.getMe.fetchQuery(),
    v3.getUserDepartments.fetchQuery(),
  ])
  return departments.data.find(
    (department) => department.name === me.department
  )
}
