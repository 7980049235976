import { Link } from "@tanstack/react-location"
import chevronIcon from "assets/icons/chevronIcon.svg"
import closeIcon from "assets/icons/closeIcon.svg"
import { useMenu, useWindowWidth } from "hooks"
import { PropsWithChildren, ReactNode, RefObject } from "react"
import { modifiers } from "ui-tools"
import { MenuAnimation } from "../MenuAnimation"
import s from "./HeaderMenu.module.scss"

type HeaderMenuProps = {
  isOpen: boolean
  toggleIsOpen: () => void
  // Wrapping HeaderMenu in React.forwardRef causes TypeScript to complain
  // about the dot notation for sub-components, so we pass the ref as a prop
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issue-433609312
  containerRef: RefObject<HTMLDivElement>
  children: ReactNode
}
export const HeaderMenu = ({
  isOpen,
  toggleIsOpen,
  children,
  containerRef,
}: HeaderMenuProps) => (
  <div className={modifiers(s, "headerMenu", { isOpen })} ref={containerRef}>
    <button
      className={s.headerMenu__button}
      onClick={toggleIsOpen}
      title="Header menu"
    />
    <MenuAnimation className={s.headerMenu__content} isOpen={isOpen}>
      {children}
    </MenuAnimation>
  </div>
)

const Content = ({ children }: PropsWithChildren) => (
  <div className={s.content}>{children}</div>
)

type ContentHeaderProps = {
  toggleIsOpen: () => void
}

const ContentHeader = ({ toggleIsOpen }: ContentHeaderProps) => {
  const { isSmallWindow } = useWindowWidth()
  if (!isSmallWindow) return null

  return (
    <div className={s.contentHeader}>
      <div className={s.contentHeader__title}>Menu</div>
      <button
        className={s.contentHeader__button}
        onClick={toggleIsOpen}
        title="Close menu"
      >
        <img src={closeIcon} alt="" />
      </button>
    </div>
  )
}

type CompanyItemProps = {
  companyName: string
  icon: string
  menu?: ReactNode
  href?: string
  to?: string
  onClick?: () => void
}
const CompanyItem = ({
  companyName,
  icon,
  menu,
  ...props
}: CompanyItemProps) => {
  const { isOpen, toggleMenu, containerRef } = useMenu()

  return (
    <div className={modifiers(s, "companyItem", { isOpen })} ref={containerRef}>
      <LinkOrButton
        className={s.companyItem__linkOrButton}
        onClick={toggleMenu}
        {...props}
      >
        <div className={s.companyItem__left}>
          <img className={s.companyItem__icon} src={icon} alt="" />
          <div className={s.companyItem__companyName}>{companyName}</div>
        </div>
        <img className={s.companyItem__chevron} src={chevronIcon} alt="" />
      </LinkOrButton>
      <MenuAnimation className={s.companyItem__menu} isOpen={isOpen}>
        {menu}
      </MenuAnimation>
    </div>
  )
}

type BigItemProps = {
  title: string
  icon: string
  description?: string
  href?: string
  to?: string
  isBigIcon?: boolean
  isInteractive?: boolean
  onClick?: () => void
}
const BigItem = ({
  title,
  icon,
  description,
  onClick,
  isBigIcon,
  ...props
}: BigItemProps) => (
  <div className={modifiers(s, "bigItem", { isBigIcon })}>
    <LinkOrButton
      className={s.bigItem__linkOrButton}
      onClick={onClick}
      openInNewTab={true}
      {...props}
    >
      <div className={s.bigItem__left}>
        <div className={s.bigItem__title}>{title}</div>
        {description && <p className={s.bigItem__description}>{description}</p>}
      </div>
      <img className={s.bigItem__icon} src={icon} alt="" />
    </LinkOrButton>
  </div>
)

type SmallItemProps = {
  title?: ReactNode
  children?: ReactNode
  to?: string
  href?: string
  onClick?: () => void
}
const SmallItem = ({ title, children, ...props }: SmallItemProps) => (
  <LinkOrButton className={s.smallItem} {...props}>
    {title}
    {children}
  </LinkOrButton>
)

type AppVersionProps = {
  version: string
}
const AppVersion = ({ version }: AppVersionProps) => (
  <div className={s.appVersion}>{version}</div>
)

HeaderMenu.Content = Content
HeaderMenu.ContentHeader = ContentHeader
HeaderMenu.CompanyItem = CompanyItem
HeaderMenu.BigItem = BigItem
HeaderMenu.SmallItem = SmallItem
HeaderMenu.AppVersion = AppVersion

type LinkOrButtonProps = {
  children: ReactNode
  className?: string
  to?: string
  href?: string
  openInNewTab?: boolean
  isInteractive?: boolean
  onClick?: () => void
}
const LinkOrButton = ({
  to,
  href,
  openInNewTab,
  isInteractive = true,
  ...props
}: LinkOrButtonProps) => {
  if (!isInteractive) {
    return <div {...props} />
  }

  if (to) {
    return <Link to={to} {...props} />
  }

  if (href) {
    const target = openInNewTab ? "_blank" : undefined
    return (
      <a href={href} target={target} {...props}>
        {props.children}
      </a>
    )
  }

  return <button {...props} />
}
