import type { ShowErrorPopupProps } from "@attensi/error-popup"
import { never } from "utils"

const errorPopupPromise = import("@attensi/error-popup").catch((error) => {
  console.error(error)
  return never()
})

const IS_DEV_BUILD = import.meta.env.DEV

export const showErrorPopup = async (props: ShowErrorPopupProps) => {
  try {
    const { showErrorPopup } = await errorPopupPromise

    showErrorPopup({ skipLandingPage: IS_DEV_BUILD, ...props })
  } catch (error) {
    console.error(error)
  } finally {
    console.error(props.error)
  }
}
