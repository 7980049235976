import { useEffect } from "react"
import { assert, literal, type } from "superstruct"

type IframeMessagesConfig = {
  onClose: () => void
  targetUrl: string
}
export const useIframeMessages = (config: IframeMessagesConfig) => {
  useEffect(() => {
    const onMessage = (event: MessageEvent) => messageListener(event, config)

    window.addEventListener("message", onMessage)
    return () => window.removeEventListener("message", onMessage)
  })
}

const messageListener = (
  event: MessageEvent,
  { onClose, targetUrl }: IframeMessagesConfig
) => {
  if (!isEventValid(event, targetUrl)) return

  const data = parseIncomingMessage(event.data)

  switch (data.type) {
    case "EXIT_MODAL":
      return onClose()
  }
}

const isEventValid = (event: MessageEvent, targetUrl: string) => {
  const eventOrigin = event.origin
  const targetOrigin = new URL(targetUrl).origin

  return eventOrigin === targetOrigin
}

const parseIncomingMessage = (data: unknown) => {
  assert(data, IncomingMessage)
  return data
}

const IncomingMessage = type({
  type: literal("EXIT_MODAL"),
})
