import { ButtonHTMLAttributes, forwardRef } from "react"
import { modifiers } from "ui-tools"
import { MateriaBorderRadius } from "../../../types/MateriaBorderRadius"
import { getCssBorderRadius } from "../../../utils/getCssValues"
import s from "./Button.module.scss"

type ButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "style" | "className"
> & {
  borderRadius?: MateriaBorderRadius
  isLoading?: boolean
  theme?: "primary" | "blue" | "cancel" | "delete"
  size?: "big" | "medium"
}
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      borderRadius,
      isLoading,
      children,
      disabled,
      theme = "primary",
      size = "medium",
      ...rest
    } = props

    return (
      <button
        ref={ref}
        type="button"
        disabled={isLoading || disabled}
        {...rest}
        className={modifiers(s, "button", { isLoading, disabled }, theme, size)}
        style={{ borderRadius: getCssBorderRadius(borderRadius) }}
      >
        <div className={s.button__content}>{children}</div>
      </button>
    )
  }
)
