import { changeAppLauncherLocale } from "@attensi/app-launcher"
import helpAttensiIcon from "assets/icons/helpAttensiIcon.svg"
import languageIcon from "assets/icons/languageIcon.svg"
import adminLogo from "assets/logos/adminLogo.svg"
import { getLinkWithActiveSession, logoutAndLogin } from "auth"
import { useQueryAll } from "bonzai"
import { useMenu } from "hooks"
import { useTranslation } from "react-i18next"
import { custom, v1 } from "src/bonzai/bonzai"
import { getHasAdminAccess } from "src/dataUtilities/getHasAdminAccess"
import { getLocaleDisplayName } from "src/dataUtilities/getLocaleDisplayName"
import { ADMIN_URL } from "src/env"
import { changeLocale } from "src/i18n/changeLocale"
import { APP_VERSION, IS_APP } from "src/vuplex/constants"
import { sendVuplexMessage } from "src/vuplex/sendVuplexMessage"
import { Dropdown, HeaderMenu } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"
import { withQueryParams } from "utils"
import { CompanyPickerLoader } from "./CompanyPickerLoader"

export const HeaderMenuLoader = () => {
  const { isOpen, toggleMenu, containerRef } = useMenu()

  return (
    <HeaderMenu
      isOpen={isOpen}
      toggleIsOpen={toggleMenu}
      containerRef={containerRef}
    >
      <QueryBoundary>
        <Load toggleMenu={toggleMenu} />
      </QueryBoundary>
    </HeaderMenu>
  )
}

type LoadProps = {
  toggleMenu: () => void
}
const Load = ({ toggleMenu }: LoadProps) => {
  const { t, i18n } = useTranslation()

  const [me, company, flags] = useData()

  const privacyPolicyUrl =
    company.privacy_policies.find((p) => p.locale === i18n.language)
      ?.html_url ?? company.privacy_policy

  const languageOptions = company.portal_supported_locales.map((locale) => (
    <Dropdown.Option key={locale.code} value={locale.code}>
      {getLocaleDisplayName(locale.code)}
    </Dropdown.Option>
  ))

  const onLogout = () => {
    sendVuplexMessage({ type: "LOGOUT" })
    logoutAndLogin()
  }

  const appVersionText =
    APP_VERSION && `${t("settings.APP_VERSION")} ${APP_VERSION}`

  const onLanguageChange = (localeCode: string) => {
    changeLocale(localeCode)
    changeAppLauncherLocale(localeCode as `${string}-${string}`)
    sendVuplexMessage({ type: "SET_LANGUAGE", payload: { localeCode } })
  }

  return (
    <HeaderMenu.Content>
      <HeaderMenu.ContentHeader toggleIsOpen={toggleMenu} />
      {!IS_APP && (
        <HeaderMenu.CompanyItem
          companyName={company.name}
          icon={company.logo}
          menu={<CompanyPickerLoader />}
        />
      )}

      {!flags.disable_user_profile && (
        <HeaderMenu.BigItem
          title={t("settings.YOUR_PROFILE")}
          description={me.position ?? ""}
          icon={me.image}
          isBigIcon={true}
          onClick={toggleMenu}
          to="/me"
        />
      )}

      <Dropdown value={i18n.language} onChange={onLanguageChange}>
        <Dropdown.CustomButton>
          <HeaderMenu.BigItem
            title={t("settings.LANGUAGE")}
            icon={languageIcon}
            description={getLocaleDisplayName(i18n.language)}
            isInteractive={false}
          />
        </Dropdown.CustomButton>
        <Dropdown.Options>{languageOptions}</Dropdown.Options>
      </Dropdown>

      <HeaderMenu.BigItem
        title={t("settings.HELP_ATTENSI")}
        icon={helpAttensiIcon}
        description={t("settings.HELP_ATTENSI_DESCRIPTION")}
        onClick={toggleMenu}
        href="https://help.attensi.com/hc/en-us"
      />

      {getHasAdminAccess(me) && (
        <HeaderMenu.SmallItem onClick={redirectToAdmin}>
          <img src={adminLogo} alt="" />
          {t("settings.ADMIN")}
        </HeaderMenu.SmallItem>
      )}

      <HeaderMenu.SmallItem
        title={t("settings.MINIMUM_TECHNICAL_SPECIFICATION")}
        href="https://legal.attensi.com/min-spec.html"
      />

      <HeaderMenu.SmallItem
        title={t("settings.PRIVACY_POLICY")}
        href={privacyPolicyUrl}
      />

      <HeaderMenu.SmallItem
        title={t("settings.TERMS_OF_USE")}
        href="https://legal.attensi.com/eula.html"
      />

      <HeaderMenu.SmallItem title={t("settings.LOGOUT")} onClick={onLogout} />

      {appVersionText && <HeaderMenu.AppVersion version={appVersionText} />}
    </HeaderMenu.Content>
  )
}

const useData = () => {
  return useQueryAll(() => [
    v1.getMe.useQuery(),
    v1.getCurrentCompany.useQuery(),
    custom.getPortalConfigurations.useQuery(),
  ])
}

const redirectToAdmin = async () => {
  const adminLink = await getLinkWithActiveSession({ url: ADMIN_URL })
  window.location.href = withQueryParams(adminLink, { beta_portal: true })
}
