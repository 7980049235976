import type { BrowserOptions } from "@sentry/react"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { getActiveSession } from "auth"
import { never } from "utils"
import { FetchError, isFetchError } from "wrap-fetch"

type Options = {
  isEnabled: boolean
  sentryUrl: string
  environment: string
  release: string
}

export const initializeSentry = async ({
  isEnabled,
  sentryUrl,
  environment,
  release,
}: Options) => {
  if (!isEnabled) return never()

  Sentry.init({
    dsn: sentryUrl,
    environment: environment,
    tracesSampleRate: 1,
    release: release,
    integrations: [new BrowserTracing()],
    beforeSend: onBeforeSend,
  })

  const { userId, companyNameKey, subdomain, impersonation } =
    await getActiveSession()

  Sentry.setUser({ id: String(userId) })

  Sentry.setTag("company_name_key", companyNameKey)

  Sentry.setContext("User Session", {
    "Company name key": companyNameKey,
    Subdomain: subdomain,
    Impersonation: impersonation,
  })

  return Sentry
}

const onBeforeSend: BrowserOptions["beforeSend"] = (event, hint) => {
  const error = hint.originalException

  if (isFetchError(error)) {
    event.fingerprint = getErrorFingerprint(error)
    event.contexts = { ...event.contexts, Request: getErrorContext(error) }
  }

  return event
}

const getErrorContext = (error: FetchError) => ({
  Method: error.method,
  URL: error.url,
  "Scrubbed URL": scrubUrl(error.url),
  "Query qarams": error.params,
  "Status code": error.status,
  "Is online?": error.isOnline,
})

const getErrorFingerprint = (error: FetchError) => [
  error.name,
  error.message,
  error.method,
  scrubUrl(error.url),
]

const scrubUrl = (text: string) => {
  const numberSegment = /\/\d+/g
  const longSegment = /\/[^./]{20,}/g
  return text
    .replace(numberSegment, "/replaced")
    .replace(longSegment, "/replaced")
}
