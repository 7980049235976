/// <reference path="deserialize-json-api.d.ts"/>
import { deserialize } from "deserialize-json-api"

export const deserializeJsonApi = (value: unknown) => {
  try {
    return deserialize(value)
  } catch {
    return value
  }
}
