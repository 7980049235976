import { QueryOptions } from "@tanstack/react-query"
import { QueryClient } from "bonzai"
import { showErrorPopup } from "error-popup"
import { isFetchError } from "wrap-fetch"

const onError = (error: unknown) => {
  showErrorPopup({ error })
}

const onRetry: QueryOptions["retry"] = (failureCount, error) => {
  const possibleNetworkError = isFetchError(error) && !error.isRequestComplete
  if (possibleNetworkError) return failureCount < 3

  return false
}

export const createQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: onRetry,
        useErrorBoundary: true,
        refetchOnWindowFocus: false,
        networkMode: "always",
      },
      mutations: {
        onError,
      },
    },
  })
}
