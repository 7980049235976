import { showErrorPopup } from "error-popup"
import { getQueryParams } from "utils"
import { z } from "zod"

const AppParams = z.object({
  app_type: z.optional(z.enum(["portal", "skills"] as const)),
  app_version: z.optional(z.string()),
})

export const getAppParams = () => {
  try {
    const queryParams = getQueryParams()
    const storedParams = getFromStorage()

    const app_type = queryParams.app_type ?? storedParams.app_type
    const app_version = queryParams.app_version ?? storedParams.app_version

    const params = AppParams.parse({ app_type, app_version })

    addToStorage(params)

    return params
  } catch (error) {
    showErrorPopup({ error })
    return {}
  }
}

const getFromStorage = (): Partial<Record<string, string>> => {
  const data = sessionStorage.getItem("app_params")
  if (data) return JSON.parse(data)
  return {}
}

const addToStorage = (data: z.infer<typeof AppParams>) => {
  const json = JSON.stringify(data)
  sessionStorage.setItem("app_params", json)
}
