import { QueryParams } from "./types"

type CreateFetchErrorProps = {
  message: string
  method: string
  url: string
  cause: Error | undefined
  status: number | undefined
  text: string | undefined
  isRequestComplete: boolean
}

export class FetchError extends Error {
  name = "AttensiFetchError"
  isOnline = window.navigator.onLine

  url: string
  method: string
  status: number | undefined
  params: QueryParams | undefined
  text: string | undefined
  json: unknown | undefined
  isRequestComplete: boolean

  constructor(props: CreateFetchErrorProps) {
    super(props.message, { cause: props.cause })

    this.url = props.url
    this.method = props.method
    this.status = props.status
    this.text = props.text
    this.json = parseJson(props.text)
    this.params = parseParams(props.url)
    this.isRequestComplete = props.isRequestComplete
  }
}

const parseJson = (text: string | undefined): undefined | any => {
  if (text === undefined) return undefined

  try {
    return JSON.parse(text)
  } catch {
    return undefined
  }
}

const parseParams = (url: string | undefined) => {
  if (url === undefined) return undefined

  try {
    const entries = new URL(url).searchParams.entries()
    return Object.fromEntries(entries)
  } catch {
    return undefined
  }
}
