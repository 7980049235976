import { initAppLauncher } from "@attensi/app-launcher"

import { getActiveSession } from "auth"
import { AUTH_URL } from "src/env"
import { getLocaleFromStorage } from "src/i18n/localeStorage"

export const initializeAppLauncher = async () => {
  const { accessToken, subdomain, companyNameKey } = await getActiveSession()
  if (!accessToken || !subdomain || !companyNameKey) {
    throw new Error("Failed to initialize app launcher")
  }

  const locale = ((await getLocaleFromStorage(companyNameKey)) ??
    "en-US") as `${string}-${string}`

  initAppLauncher({
    env: "staging",
    locale,
    authUrl: AUTH_URL,
    accessToken,
    subdomain,
    companyNameKey,
    enabledApps: [
      "ADMIN",
      "ENGAGE",
      "FAST",
      "QUIZRACE",
      "LIVE_LEADERBOARDS",
      "INSIGHTS",
      "DIALOGUE_REVIEW",
      "PROCESS_DOCS",
      "PORTAL",
      "CREATOR",
      "ATTENSI_PLATFORM",
    ],
  })
}
