import { AppLauncherButton } from "@attensi/app-launcher"
import { useEffect, useRef } from "react"

export const AppLauncherBtn = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const isLoaded = useRef(false)

  useEffect(() => {
    if (ref?.current && !isLoaded.current) {
      new AppLauncherButton({
        target: ref.current,
      })
      isLoaded.current = true
    }
  }, [])

  return <div ref={ref}></div>
}
