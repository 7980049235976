import checkmark from "assets/icons/checkmark.svg"
import chevronIcon from "assets/icons/chevronIcon.svg"
import { Children, PropsWithChildren, ReactNode } from "react"
import { modifiers } from "ui-tools"
import { backgroundImageStyle } from "../../../utils/backgroundImageStyle"
import { toPercent } from "../../../utils/toPercent"
import { Card } from "../../base/Card"
import { ProgressCircle } from "../../base/ProgressCircle"
import s from "./Journey.module.scss"

type JourneyProps = {
  backgroundImage: string
  children: ReactNode
}
export const Journey = ({ backgroundImage, children }: JourneyProps) => (
  <div className={s.journey} style={backgroundImageStyle(backgroundImage)}>
    {children}
  </div>
)

type HeaderProps = {
  onClick: () => void
  title: string
  progress: number
  progressMax: number
  isOpen: boolean
  isCertified: boolean
}
const Header = ({
  onClick,
  title,
  progress,
  progressMax,
  isOpen,
  isCertified,
}: HeaderProps) => (
  <div className={modifiers(s, "header", { isOpen })}>
    <button
      className={s.header__button}
      onClick={onClick}
      title="Toggle journey"
    >
      {isCertified ? (
        <img className={s.header__certified} src={checkmark} alt="" />
      ) : (
        <div className={s.header__progress}>
          <ProgressCircle
            color="white"
            backgroundColor="black"
            fillBackground
            value={progress}
            maxValue={progressMax}
            stroke={7}
          >
            {toPercent(progress, progressMax)}%
          </ProgressCircle>
        </div>
      )}
      <div className={s.header__title}>{title}</div>
      <div className={s.header__toggleContainer}>
        <img className={s.header__toggle} src={chevronIcon} alt="" />
      </div>
    </button>
  </div>
)

type ProductsProps = {
  isOpen: boolean
  children: ReactNode
}
const Products = ({ isOpen, children }: ProductsProps) => {
  if (!isOpen) return null

  return (
    <div className={s.products}>
      <div className={s.startBlock} />
      <div>
        {Children.map(children, (child) => (
          <JourneyCardBlock>{child}</JourneyCardBlock>
        ))}
      </div>
    </div>
  )
}

const Skeleton = () => (
  <Card borderRadius="32">
    <div className={s.skeleton} />
  </Card>
)

Journey.Header = Header
Journey.Products = Products
Journey.Skeleton = Skeleton

const JourneyCardBlock = ({ children }: PropsWithChildren) => (
  <div className={s.journeyBlock}>
    <div>{children}</div>
  </div>
)
