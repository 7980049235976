import { useQueryAll } from "bonzai"
import { formatDate } from "format"
import { useTranslation } from "react-i18next"
import { custom, v3 } from "src/bonzai/bonzai"
import {
  getHasEventExpired,
  getIsEventFull,
  pickEventDate,
} from "src/dataUtilities/eventDataUtilities"
import { getProductLink } from "src/dataUtilities/productDataUtilities"
import { ProductCardEvent } from "ui/exports/portal"

type Course = v3["getProductCourse"]["data"]

type ProductCardEventLoaderProps = {
  productId: number
  productTitle: string
}
export const ProductCardEventLoader = ({
  productId,
  productTitle,
}: ProductCardEventLoaderProps) => {
  const { t, i18n } = useTranslation()

  const [status, courses] = useData(productId)

  const course = pickEventDate(courses)

  const isCourseFullyBooked = getIsEventFull(course)

  const getInfoText = () => {
    if (course === undefined) return t("event.NO_DATES_AVAILABLE")
    if (course.is_attending) return t("event.ATTENDING")
    if (status?.certified) return t("event.COMPLETED")
    if (isCourseFullyBooked) return t("event.FULLY_BOOKED")
    return t("product.PRODUCT_TYPES.meet")
  }

  return (
    <ProductCardEvent
      isEmpty={course === undefined}
      day={course ? formatCourseDay(course) : ""}
      month={course ? formatCourseMonth(course, i18n.language) : ""}
      link={{ to: getProductLink(productId, "meet") }}
      isCertified={status?.certified ?? false}
      isMandatory={status?.is_mandatory ?? false}
      isAttending={course?.is_attending ?? false}
      isFullyBooked={isCourseFullyBooked}
      title={productTitle}
      info={getInfoText()}
      mandatoryText={t("user.MANDATORY")}
      stars={status?.certified ? 1 : 0}
      starsMax={1}
    />
  )
}

const useData = (productId: number) => {
  return useQueryAll(() => [
    custom.getProductProgress.useQuery([productId, { apiVersion: "v1" }], {
      suspense: false,
    }),

    v3.getProduct.useQuery([productId, { include: ["courses"] }], {
      select: (res) =>
        res.data.courses?.filter((date) => !getHasEventExpired(date)) ?? [],
    }),
  ])
}

const formatCourseDay = (course: Course) => {
  const date = new Date(course.start_datetime)
  // Didn't use intl here since we didn't like the periods added to norwegian dates.
  return date.getDate().toString()
}

const formatCourseMonth = (course: Course, locale: string) => {
  return formatDate(course.start_datetime, locale, {
    month: "short",
  })
}
