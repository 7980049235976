import { getActiveSession, refreshLogin } from "auth"
import { assert, Struct } from "superstruct"
import { blobToText, getQueryParams, waitMillis } from "utils"
import { FetchError, QueryParams, wrapFetch, WrapFetchProps } from "wrap-fetch"
import { getConfig } from "../apiConfig"

const { delay } = getQueryParams()

export const get = <T>(
  schema: Struct<T>,
  path: string,
  params?: QueryParams
) => {
  return v1Fetch({
    method: "GET",
    path,
    params,
    transform: (response) => transformResponse(schema, response),
  })
}

export const post = <T>(schema: Struct<T>, path: string, data: any) => {
  return v1Fetch({
    method: "POST",
    path,
    data,
    transform: (response) => transformResponse(schema, response),
  })
}

// Jank from the api. It sometimes returns data on post, sometimes not.
export const postNoResponse = (path: string, data: any) => {
  return v1Fetch({
    method: "POST",
    path,
    data,
  })
}

export const patch = <T>(schema: Struct<T>, path: string, data: any) => {
  return v1Fetch({
    method: "PATCH",
    path,
    data,
    transform: (response) => transformResponse(schema, response),
  })
}

export const del = <T>(schema: Struct<T>, path: string, data: any) => {
  return v1Fetch({
    method: "DELETE",
    path,
    data,
    transform: (response) => transformResponse(schema, response),
  })
}

const v1Fetch = async <T>(
  options: Omit<WrapFetchProps<T>, "pathPrefix" | "headers" | "baseUrl">
) => {
  const { apiBaseUrl } = await getConfig()

  if (delay) {
    await waitMillis(Number(delay))
  }

  return wrapFetch({
    pathPrefix: "/api/v1",
    headers: await getHeaders?.(),
    baseUrl: apiBaseUrl,
    onError: onError,
    ...options,
  })
}

const getHeaders = async () => {
  const { apiToken } = await getConfig()
  const { accessToken } = await getActiveSession()
  return {
    Authorization: "Bearer " + accessToken,
    "API-Token": apiToken,
  }
}

const onError = async (error: FetchError) => {
  if (error.status === 401) {
    await refreshLogin()
  }
}

const transformResponse = async <T>(schema: Struct<T>, response: Blob) => {
  const data = await JSON.parse(await blobToText(response))
  assert(data, schema)
  return data
}
