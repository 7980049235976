import { apiV1, apiV3 } from "api"
import * as authApi from "auth"
import { bonzai, InferBonzai } from "bonzai"
import * as customApi from "../customApi"

export type v1 = InferBonzai<typeof v1>
export const v1 = bonzai(apiV1)

export type v3 = InferBonzai<typeof v3>
export const v3 = bonzai(apiV3)

export type auth = InferBonzai<typeof auth>
export const auth = bonzai(authApi)

export type custom = InferBonzai<typeof custom>
export const custom = bonzai(customApi)
