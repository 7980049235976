import { upperFirst } from "lodash-es"
import { v3 } from "src/bonzai/bonzai"
import { getTimeDifference } from "./getTimeDifference"

type EventDate = v3["getProductCourse"]["data"]

export const formatEventDate = (eventDate: EventDate, locale: string) => {
  const date = new Date(eventDate.start_datetime)
  const month = new Intl.DateTimeFormat(locale, { month: "short" }).format(date)
  const day = new Intl.DateTimeFormat(locale, { day: "2-digit" }).format(date)
  const time = new Intl.DateTimeFormat(locale, {
    hour: "numeric",
    minute: "numeric",
  }).format(date)

  return { month, day, time }
}

export const formatEventFullDate = (eventDate: EventDate, locale: string) => {
  const date = new Date(eventDate.start_datetime)
  const formatted = new Intl.DateTimeFormat(locale, {
    weekday: "long",
    month: "short",
    day: "numeric",
  }).format(date)
  return upperFirst(formatted)
}

export const formatEventTime = (eventDate: EventDate, locale: string) => {
  const startDate = new Date(eventDate.start_datetime)
  const endDate = new Date(eventDate.end_datetime)

  const dtl = new Intl.DateTimeFormat(locale, {
    hour: "numeric",
    minute: "numeric",
  })

  return dtl.format(startDate) + " - " + dtl.format(endDate)
}

export const formatEventDayMonth = (eventDate: EventDate, locale: string) => {
  const startDateTime = new Date(eventDate.start_datetime)
  const endDateTime = new Date(eventDate.end_datetime)

  const startDate = new Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "short",
  }).format(startDateTime)
  const endDate = new Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "short",
  }).format(endDateTime)

  if (startDateTime.getDay() !== endDateTime.getDay())
    return `${startDate} - ${endDate}`
  return startDate
}

export const formatEventDay = (eventDate: EventDate, locale: string) => {
  const startDateTime = new Date(eventDate.start_datetime)
  const endDateTime = new Date(eventDate.end_datetime)

  const startDate = new Intl.DateTimeFormat(locale, {
    day: "numeric",
  }).format(startDateTime)

  const endDate = new Intl.DateTimeFormat(locale, {
    day: "numeric",
  }).format(endDateTime)

  if (startDateTime.getDay() !== endDateTime.getDay())
    return `${startDate} - ${endDate}`
  return startDate
}

export const formatEventLength = (eventDate: EventDate, locale: string) => {
  const names = new Intl.DisplayNames(locale, {
    type: "dateTimeField",
    style: "short",
  })

  const startDate = new Date(eventDate.start_datetime)
  const endDate = new Date(eventDate.end_datetime)
  const { seconds, days, hours, minutes, months, weeks, years } =
    getTimeDifference(startDate, endDate)

  if (years > 0) return `${years} ${names.of("year")}`
  if (months > 0) return `${months} ${names.of("month")}`
  if (weeks > 0) return `${weeks} ${names.of("weekOfYear")}`
  if (days > 0) return `${days} ${names.of("day")}`
  if (hours > 0) return `${hours} ${names.of("hour")}`
  if (minutes > 0) return `${minutes} ${names.of("minute")}`
  return `${seconds} ${names.of("second")}`
}

export const pickEventDate = (dates: EventDate[]) => {
  const validDates = dates.filter((date) => !date.cancelled)
  const firstAttendingDate = validDates.find(
    (date) => date.is_attending || date.is_on_waiting_list
  )
  const firstDate = validDates[0]
  return firstAttendingDate ?? firstDate
}

export const getHasEventExpired = (event: EventDate) => {
  const eventDate = new Date(event.start_datetime).getTime()
  return eventDate <= Date.now()
}

export const getIsEventFull = (event: EventDate | undefined) => {
  if (event === undefined) return false

  return event.attendees_count >= event.attendees_count_max
}
