import { useModal, useRouteParamNumber } from "hooks"
import { v1 } from "src/bonzai/bonzai"
import { createPlaythrough } from "src/bonzai/createPlaythrough"
import { getVideoLink } from "src/dataUtilities/getVideoLink"
import { usePickText } from "src/i18n/usePickText"
import { Loader, ViewerModal } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type VideoModalLoaderProps = {
  closeRoute: string
}
export const VideoModalLoader = ({ closeRoute }: VideoModalLoaderProps) => {
  const productId = useRouteParamNumber("productId")

  const { onClose } = useModal(closeRoute, () => {
    createPlaythrough(productId, {
      mutate: false,
      optimisticOneStar: true,
      invalidateDependencies: true,
    })
  })

  return (
    <ViewerModal onClose={onClose}>
      <QueryBoundary fallback={<Loader />}>
        <Load productId={productId} onClose={onClose} />
      </QueryBoundary>
    </ViewerModal>
  )
}

type VideoViewerLoaderProps = {
  productId: number
  onClose: () => void
}
const Load = ({ productId, onClose }: VideoViewerLoaderProps) => {
  const pickText = usePickText()

  const product = v1.getProduct.useQuery([productId])

  const videoLink = getVideoLink(product.content_link)

  return (
    <ViewerModal.FullScreen
      headerText={pickText(product.titles)}
      src={videoLink}
      onClose={onClose}
    />
  )
}
