import { PropsWithChildren, ReactNode } from "react"
import { createPortal } from "react-dom"
import { modifiers } from "ui-tools"
import { usePortalTarget } from "utility-components"
import s from "./Tooltip.module.scss"
import { TooltipContextProvider, useTooltipContext } from "./TooltipContext"
import { TooltipConfig, useTooltip } from "./useTooltip"

type TooltipProps = PropsWithChildren & TooltipConfig

export const Tooltip = ({ children, ...config }: TooltipProps) => {
  const tooltipContext = useTooltip(config)

  return (
    <TooltipContextProvider value={tooltipContext}>
      {children}
    </TooltipContextProvider>
  )
}

type TriggerProps = {
  className?: string
  children: ReactNode
}
const Trigger = ({ children, className = "" }: TriggerProps) => {
  const { triggerProps } = useTooltipContext()

  return (
    <button {...triggerProps} className={className}>
      {children}
    </button>
  )
}

const Content = ({ children }: PropsWithChildren) => {
  const { isMounted, contentProps, arrowProps, config, position } =
    useTooltipContext()

  const { ref } = usePortalTarget()

  if (!isMounted) return null

  const theme = config.theme ?? "white"

  const element = (
    <div
      className={modifiers(s, "content", theme, position)}
      {...contentProps}
      data-chromatic="ignore"
    >
      <div className={s.content__arrow} {...arrowProps} />
      <div className={s.content__container}>{children}</div>
    </div>
  )

  const targetNode = ref.current
  if (!targetNode) return element

  return createPortal(element, targetNode)
}

Tooltip.Trigger = Trigger
Tooltip.Content = Content
