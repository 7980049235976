type Config = {
  timeout?: number
}

export const createResolvable = <T>(config: Config = {}) => {
  const { timeout } = config

  let resolvePromise: (value: T) => void
  let rejectPromise: (reason?: Error) => void

  const promise = new Promise<T>((resolve, reject) => {
    resolvePromise = resolve
    rejectPromise = reject

    if (timeout === undefined) return

    setTimeout(() => {
      reject(new Error("Resolvable timed out"))
    }, timeout)
  })

  return {
    promise,
    resolve: resolvePromise!,
    reject: rejectPromise!,
  }
}
