import { custom } from "src/bonzai/bonzai"
import { UserInfoHeader } from "ui/exports/portal"
import { KpiSalesHeaderLoader } from "./KpiSalesHeaderLoader"
import { UserInfoHeaderLoader } from "./UserInfoHeaderLoader"

type SubHeadersLoaderProps = {
  showUserInfoHeader: boolean | undefined
}
export const SubHeadersLoader = ({
  showUserInfoHeader = true,
}: SubHeadersLoaderProps) => {
  const flags = custom.getPortalConfigurations.useQuery({ suspense: false })

  if (!flags) return <UserInfoHeader.Skeleton />

  return (
    <>
      {!flags.disable_completed_courses_stars && showUserInfoHeader && (
        <UserInfoHeaderLoader />
      )}
      {flags.enable_kpi_sales_tiles && <KpiSalesHeaderLoader />}
    </>
  )
}
