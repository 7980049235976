import * as Sentry from "@sentry/react"
import { showErrorPopup } from "error-popup"
import { ReactNode, Suspense } from "react"
import { ErrorBoundary } from "react-error-boundary"

type QueryBoundaryProps = {
  fallback?: ReactNode
  children?: ReactNode
  isSuspense?: boolean
}
export const QueryBoundary = ({
  fallback,
  children,
  isSuspense = true,
}: QueryBoundaryProps) => (
  <ErrorBoundary
    fallback={<></>}
    onError={(error) => {
      showErrorPopup({ error })
      Sentry.captureException(error)
    }}
  >
    {isSuspense ? (
      <Suspense fallback={fallback}>{children}</Suspense>
    ) : (
      children
    )}
  </ErrorBoundary>
)
