import { Listbox, Transition } from "@headlessui/react"
import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import { ReactComponent as Chevron } from "assets/icons/chevronIcon.svg"
import { Fragment, PropsWithChildren, ReactNode } from "react"
import { modifiers } from "ui-tools"
import { Card } from "../Card"
import s from "./Dropdown.module.scss"

type DropdownProps<T, M> = M extends true
  ? {
      multiple: true
      value: T[]
      onChange: (value: T[]) => void
      children: ReactNode
    }
  : {
      multiple?: M
      value: T
      onChange: (value: T) => void
      children: ReactNode
    }

/*
 * Docs: https://headlessui.com/react/listbox
 */
export const Dropdown = <T, M extends boolean>({
  value,
  onChange,
  children,
  multiple,
}: DropdownProps<T, M>) => (
  <Listbox
    className={s.dropdown}
    value={value}
    onChange={onChange}
    multiple={multiple}
    as="div"
  >
    {children}
  </Listbox>
)

const Button = ({ children }: PropsWithChildren) => (
  <Listbox.Button as={Fragment}>
    {({ open }) => (
      <button className={modifiers(s, "button", { open })}>
        <div className={s.button__content}>{children}</div>
        <Chevron className={s.button__icon} />
      </button>
    )}
  </Listbox.Button>
)

const CustomButton = ({ children }: PropsWithChildren) => (
  <Listbox.Button as={Fragment}>
    {({ open }) => (
      <button className={modifiers(s, "customButton", { open })}>
        {children}
      </button>
    )}
  </Listbox.Button>
)

const Options = ({ children }: PropsWithChildren) => (
  <Animation>
    <Listbox.Options>
      <Card>{children}</Card>
    </Listbox.Options>
  </Animation>
)

type OptionProps<T> = {
  value: T
  children: ReactNode
}
const Option = <T,>({ value, children }: OptionProps<T>) => (
  <Listbox.Option value={value} as={Fragment}>
    {({ active, selected }) => (
      <li className={modifiers(s, "option", { active, selected })}>
        {children}
        {selected && (
          <img className={s.option__icon} src={checkmarkCircleGreen} alt="" />
        )}
      </li>
    )}
  </Listbox.Option>
)

const ChevronIcon = () => <Chevron className={s.customButton__icon} />

Dropdown.Button = Button
Dropdown.CustomButton = CustomButton
Dropdown.Options = Options
Dropdown.Option = Option
Dropdown.ChevronIcon = ChevronIcon

/*
 * Docs: https://headlessui.com/react/transition
 */
const Animation = ({ children }: PropsWithChildren) => (
  <Transition
    className={s.animation}
    enter={s["animation--enter"]}
    enterFrom={s["animation--enterFrom"]}
    enterTo={s["animation--enterTo"]}
    leave={s["animation--leave"]}
    leaveFrom={s["animation--leaveFrom"]}
    leaveTo={s["animation--leaveTo"]}
  >
    {children}
  </Transition>
)
