import { useToggle, useWindowWidth } from "hooks"
import { ComponentProps, MouseEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { auth, custom, v1 } from "src/bonzai/bonzai"
import { createPlaythrough } from "src/bonzai/createPlaythrough"
import { DEVICE_PLATFORM } from "src/constants"
import { getLocaleDisplayName } from "src/dataUtilities/getLocaleDisplayName"
import {
  getInstallAppLink,
  getIsPlayable,
  getPreferredUpload,
} from "src/dataUtilities/productDataUtilities"
import { getLauncherLink } from "src/tools/getLauncherLink"
import { IS_APP } from "src/vuplex/constants"
import { isHandledByVuplex } from "src/vuplex/isHandledByVuplex"
import { sendOpenProductMessage } from "src/vuplex/sendOpenProductMessage"
import { Dropdown, LauncherOverlay, ProductModal } from "ui/exports/portal"
import { FiestaLinkProps } from "utility-components"
import { HelpModalLoader } from "./HelpModalLoader"

type Product = v1["getProduct"]
type Upload = Product["uploads"][number]

type ProductProps = {
  product: Product
}

export const ProductModalLoaderButtons = ({ product }: ProductProps) => {
  const { product_type } = product

  if (getIsPlayable(product)) {
    return <PlayableProductButtons product={product} />
  }

  switch (product_type) {
    case "app":
      return <AppProductButtons product={product} />
    case "activity":
      return null
    case "fast":
      return <FastProductButton product={product} />
    case "file":
      return <FileProductButton product={product} />
    case "link":
      return <LinkProductButton product={product} />
    case "meet":
      return null
    case "pdf":
      return <PdfProductButton product={product} />
    case "quiz_race":
      return <QuizRaceProductButton product={product} />
    case "video":
      return <VideoProductButton product={product} />
    case "xapi":
      return <XapiProductButton product={product} />
    default:
      return <div>"{product_type}" is not supported yet.</div>
  }
}

const XapiProductButton = ({ product }: ProductProps) => {
  const { t } = useTranslation()

  const link = auth.getLinkWithActiveSession.useQuery([
    {
      url: product.content_link,
      accessType: "accessToken",
    },
  ])

  return (
    <PlayLink product={product} target="_blank" href={link}>
      {t("product.PLAY_TEXT_SIM")}
    </PlayLink>
  )
}

const VideoProductButton = ({ product }: ProductProps) => {
  const { t } = useTranslation()
  return (
    <PlayLink
      product={product}
      to="video"
      onClick={() => createPlaythrough(product.id)}
    >
      {t("product.PLAY_TEXT_VIDEO")}
    </PlayLink>
  )
}

const FastProductButton = ({ product }: ProductProps) => {
  const { t } = useTranslation()
  return (
    <PlayLink product={product} to="web-games">
      {t("product.PLAY_TEXT_SIM")}
    </PlayLink>
  )
}

const QuizRaceProductButton = ({ product }: ProductProps) => {
  const { t } = useTranslation()
  return (
    <PlayLink
      product={product}
      to="web-games"
      onClick={() => createPlaythrough(product.id)}
    >
      {t("product.PLAY_TEXT_SIM")}
    </PlayLink>
  )
}

const PdfProductButton = ({ product }: ProductProps) => {
  const { t } = useTranslation()
  const { id, uploads } = product
  const { selectedUpload, dropdown } = useUploadDropdown(uploads)
  const locale = selectedUpload?.locale

  const { isSmallWindow } = useWindowWidth()
  const pdfSrc = getPreferredUpload(product, locale)

  const playLinkProps: FiestaLinkProps = isSmallWindow
    ? {
        href: pdfSrc,
        target: "_blank",
        onClick: () =>
          createPlaythrough(id, {
            optimisticOneStar: true,
            optimisticOnFocus: true,
            invalidateDependencies: true,
          }),
      }
    : {
        to: "pdf",
        search: (search) => ({ ...search, locale }),
        onClick: () => createPlaythrough(id),
      }

  return (
    <>
      {dropdown}
      <PlayLink product={product} {...playLinkProps}>
        {t("product.PLAY_TEXT_PDF")}
      </PlayLink>
    </>
  )
}

const FileProductButton = ({ product }: ProductProps) => {
  const { t } = useTranslation()
  const { id, content_link, uploads } = product
  const { selectedUpload, dropdown } = useUploadDropdown(uploads)

  return (
    <>
      {dropdown}
      <PlayLink
        product={product}
        href={selectedUpload?.file_url ?? content_link}
        iconVariant="download"
        target="_blank"
        onClick={() =>
          createPlaythrough(id, {
            optimisticOneStar: true,
            invalidateDependencies: true,
          })
        }
      >
        {t("product.DOWNLOAD_TEXT_SIM")}
      </PlayLink>
    </>
  )
}

const LinkProductButton = ({ product }: ProductProps) => {
  const { t } = useTranslation()

  return (
    <PlayLink
      product={product}
      target="_blank"
      href={product.content_link}
      onClick={() =>
        createPlaythrough(product.id, {
          optimisticOneStar: true,
          optimisticOnFocus: true,
          invalidateDependencies: true,
        })
      }
    >
      {t("product.PLAY_TEXT_LINK")}
    </PlayLink>
  )
}

const PlayableProductButtons = ({ product }: ProductProps) => {
  const [showLauncherOverlay, toggleLauncherOverlay] = useToggle()
  const [showHelpModal, toggleHelpModal] = useToggle()

  const { t } = useTranslation()
  const links = custom.getPlayLinks.useQuery([product.id])
  const { installAppLink, launcherLink, startAppLink, webGLLink } = links

  const hasLink = webGLLink || startAppLink || launcherLink
  if (!hasLink) {
    return <NotSupported product={product} />
  }

  const appCanHandleDownload = IS_APP && startAppLink !== undefined
  const showDownloadLink = installAppLink && !appCanHandleDownload
  const showVppLink =
    product.has_vpp_codes && DEVICE_PLATFORM.platform === "iOS"
  const hasMultiplePlayButtons = launcherLink && webGLLink
  const webglLinkProps: Partial<PlayLinkProps> = product.webgl_responsive
    ? { to: "play" }
    : { target: "_blank", href: webGLLink }

  return (
    <>
      {showVppLink && (
        <PlayLink product={product} iconVariant="download" to="vpp">
          {t("product.DOWNLOAD_TEXT_SIM")}
        </PlayLink>
      )}

      {showDownloadLink && (
        <PlayLink
          product={product}
          href={installAppLink}
          target="_blank"
          iconVariant="download"
        >
          {t("product.DOWNLOAD_TEXT_SIM")}
        </PlayLink>
      )}

      {startAppLink && (
        <PlayLink product={product} href={startAppLink}>
          {t("product.PLAY_TEXT_SIM")}
        </PlayLink>
      )}

      {webGLLink && (
        <PlayLink product={product} {...webglLinkProps}>
          {hasMultiplePlayButtons
            ? t("content.PRODUCT_WEBGL_LAUNCHER")
            : t("product.PLAY_TEXT_SIM")}
        </PlayLink>
      )}

      {launcherLink && (
        <PlayLink
          product={product}
          href={launcherLink}
          onClick={toggleLauncherOverlay}
          linkVariant={hasMultiplePlayButtons ? "white" : "green"}
        >
          {hasMultiplePlayButtons
            ? t("content.PRODUCT_DESKTOP_LAUNCHER")
            : t("product.PLAY_TEXT_SIM")}
        </PlayLink>
      )}

      {hasMultiplePlayButtons && (
        <ProductModal.HelpIcon onClick={toggleHelpModal} />
      )}

      {showHelpModal && <HelpModalLoader onToggle={toggleHelpModal} />}

      {showLauncherOverlay && (
        <LauncherOverlay
          textLine1={t("product.STARTSIM_DESC")}
          textLine2={t("settings.DOWNLOAD_LAUNCHER_PROMPT")}
          downloadText={t("settings.DOWNLOAD_LAUNCHER_PROMPT_2")}
          backText={t("product.STARTSIM_DESC_BACK")}
          downloadLink={getLauncherLink()}
          onBack={toggleLauncherOverlay}
        />
      )}
    </>
  )
}

const AppProductButtons = ({ product }: ProductProps) => {
  const { t } = useTranslation()
  const { id, content_link } = product

  if (!DEVICE_PLATFORM.isAppPlatform) {
    return <NotSupported product={product} />
  }

  const installAppLink = getInstallAppLink(product)
  const vuplexCanHandleDownload = IS_APP && Boolean(content_link)
  const showDownloadLink = installAppLink && !vuplexCanHandleDownload

  return (
    <>
      {showDownloadLink && (
        <PlayLink
          product={product}
          href={installAppLink}
          target="_blank"
          iconVariant="download"
          onClick={() =>
            createPlaythrough(id, {
              optimisticOneStar: true,
              optimisticOnFocus: true,
              invalidateDependencies: true,
            })
          }
        >
          {t("product.DOWNLOAD_TEXT_SIM")}
        </PlayLink>
      )}

      {content_link && (
        <PlayLink product={product} href={content_link} target="_blank">
          {t("product.PLAY_TEXT_SIM")}
        </PlayLink>
      )}
    </>
  )
}

const NotSupported = ({ product }: ProductProps) => {
  const { t } = useTranslation()
  const { platform } = DEVICE_PLATFORM
  const title = `${t("product.NOT_SUPPORTED_GENERIC")} ${platform}`
  const platforms = product.supported_platforms.join(" / ")

  return (
    <ProductModal.NotSupported
      title={title}
      supportedBy={t("product.SUPPORTED_PLATFORMS")}
      platforms={platforms}
    />
  )
}

type PlayLinkProps = ComponentProps<typeof ProductModal.PlayLink> & {
  product: Product
}
const PlayLink = ({ product, ...props }: PlayLinkProps) => {
  const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    props.onClick?.(event)

    if (IS_APP && isHandledByVuplex(product.product_type)) {
      event.preventDefault()

      if (props.href === undefined) {
        throw new Error("PlayLink requires href when opening in skills")
      }

      sendOpenProductMessage(product, props.href)
    }
  }

  return <ProductModal.PlayLink {...props} onClick={onClick} />
}

const useUploadDropdown = (uploads: Upload[]) => {
  const { i18n } = useTranslation()

  const preferredUpload = uploads.find(
    (upload) => upload.locale === i18n.language
  )

  const [selectedUpload, setSelectedUpload] = useState(
    preferredUpload ?? uploads[0]
  )

  const options = uploads.map((upload) => (
    <Dropdown.Option key={upload.id} value={upload}>
      {getLocaleDisplayName(upload.locale)}
    </Dropdown.Option>
  ))

  const dropdown = selectedUpload && !preferredUpload && (
    <ProductModal.LanguageDropdown>
      <Dropdown value={selectedUpload} onChange={setSelectedUpload}>
        <Dropdown.Button>
          {getLocaleDisplayName(selectedUpload.locale)}
        </Dropdown.Button>
        <Dropdown.Options>{options}</Dropdown.Options>
      </Dropdown>
    </ProductModal.LanguageDropdown>
  )

  return { selectedUpload, dropdown }
}
