import { ReactComponent as LikeIcon } from "assets/icons/likeIcon.svg"
import likeIconStarBackground from "assets/icons/likeIconStarBackground.svg"
import { useEffect, useRef } from "react"
import { modifiers } from "ui-tools"
import { FiestaImage, FiestaLink, FiestaLinkProps } from "utility-components"
import { sanitizeHtml } from "../../../utils/sanitizeHtml"
import { Card } from "../../base/Card"
import s from "./NewsCard.module.scss"

type NewsCardProps = {
  link: FiestaLinkProps
  title: string
  displayDate: string
  image: string
  description: string
  numberOfLikes: number
  likeText: string
  readMoreText: string
  isLiked: boolean
  onClick: () => void
}
export const NewsCard = ({
  link,
  title,
  displayDate,
  image,
  description,
  numberOfLikes,
  likeText,
  readMoreText,
  isLiked,
  onClick,
}: NewsCardProps) => (
  <Card borderRadius="24">
    <div className={modifiers(s, "newsCard", { isLiked })}>
      <div className={s.newsCard__top}>
        <div className={s.newsCard__title}>{title}</div>
        <div className={s.newsCard__date}>{displayDate}</div>
      </div>
      <FiestaLink {...link} title={readMoreText}>
        <FiestaImage
          className={s.newsCard__image}
          src={image}
          alt="News post"
          srcSet={[500, 750, 1000, 1250]}
          sizes="(width = big) 500px, 100vw"
        />
      </FiestaLink>
      <div
        className={s.newsCard__description}
        dangerouslySetInnerHTML={sanitizeHtml(description, "strict")}
      />
      <div className={s.newsCard__bottom}>
        <NewsCardLikeButton
          isLiked={isLiked}
          onClick={onClick}
          text={likeText}
        />
        <div className={s.newsCard__likes}>
          <LikeIcon />
          {numberOfLikes}
        </div>
        <FiestaLink {...link} className={s.newsCard__readMore}>
          {readMoreText}
        </FiestaLink>
      </div>
    </div>
  </Card>
)

type NewsCardLikeButtonProps = {
  text: string
  isLiked: boolean
  onClick: () => void
}
export const NewsCardLikeButton = ({
  text,
  isLiked,
  onClick,
}: NewsCardLikeButtonProps) => {
  const enableAnimations = useAnimateOnChange(isLiked)

  return (
    <button
      className={modifiers(s, "likeButton", { isLiked, enableAnimations })}
      onClick={onClick}
    >
      <div className={s.likeButton__iconContainer}>
        <img
          className={s.likeButton__background}
          src={likeIconStarBackground}
          alt=""
        />
        <LikeIcon className={s.likeButton__icon} />
      </div>
      <span>{text}</span>
    </button>
  )
}

const useAnimateOnChange = (value: boolean) => {
  const enableAnimations = useRef(false)
  const initialValue = useRef(value)
  const hasChanged = initialValue.current !== value

  useEffect(() => {
    if (hasChanged) {
      enableAnimations.current = true
    }
  }, [hasChanged])

  return enableAnimations.current || hasChanged
}

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton}>
      <div className={s.skeleton__top} />
      <div className={s.skeleton__image} />
      <ul className={s.skeleton__description}>
        <li className={s.skeleton__descriptionItem} />
        <li className={s.skeleton__descriptionItem} />
        <li className={s.skeleton__descriptionItem} />
      </ul>
      <div className={s.skeleton__bottom}>
        <div className={s.skeleton__likeContainer}>
          <div className={s.skeleton__likeButton} />
          <div className={s.skeleton__likes} />
        </div>
        <div className={s.skeleton__readMore} />
      </div>
    </div>
  </Card>
)

NewsCard.Skeleton = Skeleton
