import { useNavigate, useSearch } from "@tanstack/react-location"
import { exists } from "utils"
import { useStableCallback } from "./useStableCallback"

export type SetQueryParam<T> = (
  value: T | undefined | ((_value: T | undefined) => T | undefined)
) => void

export const useQueryParam = <T extends string = string>(
  key: string
): [T | undefined, SetQueryParam<T>] => {
  const params = useSearch()
  const navigate = useNavigate()

  const getSearchParams = (value: T | undefined) => {
    return exists(value) && value !== ""
      ? { ...params, [key]: value }
      : { ...params, [key]: undefined }
  }

  const setValue = useStableCallback<SetQueryParam<T>>((newValue) => {
    if (typeof newValue === "function") {
      const existingValue = toString(params[key]) as T | undefined
      newValue = newValue(existingValue)
    }

    navigate({
      replace: true,
      search: getSearchParams(newValue),
    })
  })

  const value = toString(params[key]) as T | undefined
  return [value, setValue]
}

const toString = (value: unknown) => {
  if (value === undefined) return
  if (typeof value === "string") return value
  return String(value)
}
