import { useLayoutEffect } from "react"

const IS_TEST_BUILD = import.meta.env.MODE === "test"

export const useScrollToTopOnMount = () => {
  useLayoutEffect(() => {
    if (IS_TEST_BUILD) return

    window.scrollTo(0, 0)
  }, [])
}
