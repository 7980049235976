import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import starFilled from "assets/icons/starFilled.svg"
import { useWindowWidth } from "hooks"
import { PropsWithChildren } from "react"
import { modifiers } from "ui-tools"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { toPercent } from "../../../utils/toPercent"
import { ProgressBar } from "../../base/ProgressBar"
import s from "./UserInfoHeader.module.scss"

export const UserInfoHeader = ({ children }: PropsWithChildren) => {
  const { isBigWindow } = useWindowWidth()
  if (isBigWindow) return null

  return <div className={s.header}>{children}</div>
}

const Row = ({ children }: PropsWithChildren) => (
  <div className={s.row}>{children}</div>
)

type CompletedProps = {
  value: number
  maxValue: number
  label: string
  isActive: boolean
}
const Completed = ({ value, maxValue, label, isActive }: CompletedProps) => (
  <div className={modifiers(s, "completed", { isActive })}>
    <img className={s.completed__icon} src={checkmarkCircleGreen} alt="" />
    <div className={s.completed__value}>{toPercent(value, maxValue)}%</div>
    <div className={s.completed__label}>{label}</div>
  </div>
)

type StarsProps = {
  value: number
  maxValue: number
}
const Stars = ({ value, maxValue }: StarsProps) => (
  <div className={s.stars}>
    <img className={s.stars__icon} src={starFilled} alt="" />
    <div className={s.stars__value}>
      {value}/{maxValue}
    </div>
  </div>
)

type MandatoryProps = {
  value: number
  maxValue: number
  label: string
  link: FiestaLinkProps
  isVisible: boolean
  isActive: boolean
}
const Mandatory = ({
  label,
  value,
  maxValue,
  link,
  isVisible,
  isActive,
}: MandatoryProps) => {
  if (!isVisible) return null

  return (
    <FiestaLink className={modifiers(s, "mandatory", { isActive })} {...link}>
      <div className={s.mandatory__value}>
        {value}/{maxValue}
      </div>
      <div className={s.mandatory__label}>{label}</div>
    </FiestaLink>
  )
}

type ProgressProps = {
  value: number
  maxValue: number
  variant: "mandatory" | "completed"
}
const Progress = ({ value, maxValue, variant }: ProgressProps) => (
  <div className={s.progress}>
    <ProgressBar
      value={value}
      maxValue={maxValue}
      color={variant === "completed" ? "green-30" : "purple-40"}
      backgroundColor={variant === "completed" ? "green-10" : "marble"}
    />
  </div>
)

const Skeleton = () => (
  <div className={s.skeleton}>
    <div className={s.skeleton__completed} />
    <div className={s.skeleton__stars} />
    <div className={s.skeleton__mandatory} />
  </div>
)

UserInfoHeader.Row = Row
UserInfoHeader.Completed = Completed
UserInfoHeader.Stars = Stars
UserInfoHeader.Mandatory = Mandatory
UserInfoHeader.Progress = Progress
UserInfoHeader.Skeleton = Skeleton
