import searchIcon from "assets/icons/searchIcon.svg"
import { forwardRef, InputHTMLAttributes } from "react"
import { modifiers } from "ui-tools"
import s from "./Input.module.scss"

type InputProps = {
  label?: string
  infoText?: string
  disabled?: boolean
  errorMessage?: string
  isSearch?: boolean
} & InputHTMLAttributes<HTMLInputElement>

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { label, infoText, disabled, errorMessage, isSearch, ...rest } = props

  return (
    <label className={modifiers(s, "input", { disabled, isSearch })}>
      {label}

      <div className={s.input__inputContainer}>
        <input
          ref={ref}
          className={s.input__input}
          disabled={disabled}
          {...rest}
        />

        {isSearch && (
          <img className={s.input__searchIcon} src={searchIcon} alt="" />
        )}
      </div>

      {errorMessage ? (
        <p className={s.input__error}>{errorMessage}</p>
      ) : (
        <p className={s.input__info}>{infoText}</p>
      )}
    </label>
  )
})
