import { getFetchQuery } from "./fetchQuery"
import { getFetchQueryOnce } from "./fetchQueryOnce"
import { getGetQueryData } from "./getQueryData"
import { getInvalidateQueries } from "./invalidateQueries"
import { getPrefetchQuery } from "./prefetchQuery"
import { getSetQueriesData } from "./setQueriesData"
import { getSetQueryData } from "./setQueryData"
import { BonzaiMethod, BonzaiReturn, FnBase, FunctionsBase } from "./types"
import { getUseInfiniteQuery } from "./useInfiniteQuery"
import { getUseMutation } from "./useMutation"
import { getUseQueries } from "./useQueries"
import { getUseQuery } from "./useQuery"
import { getQueryPrefix } from "./utils/getQueryPrefix"

export const bonzai = <Functions extends FunctionsBase>(
  functions: Functions
): BonzaiReturn<Functions> => {
  const queryPrefix = getQueryPrefix()
  return createProxy([queryPrefix], functions)
}

const createProxy = <Functions extends FunctionsBase>(
  path: string[],
  node: FunctionsBase | FnBase
): BonzaiReturn<Functions> => {
  const empty = () => {}
  return new Proxy(empty, {
    get: (_, propertyName: string) => {
      if (typeof node === "function") {
        const methodName = propertyName as BonzaiMethod
        return pickBonzaiMethod(methodName, node, path)
      }

      if (typeof node === "object") {
        const childNode = node[propertyName]
        const nextPath = [...path, propertyName]
        return createProxy(nextPath, childNode!)
      }
    },
    apply: (_, __, args) => {
      if (typeof node !== "function") throw new Error("node is not a function")
      return node(...args)
    },
  }) as any // Unable to make TypeScript happy here without hacking any
}

const pickBonzaiMethod = (
  methodName: BonzaiMethod,
  fn: FnBase,
  path: string[]
) => {
  switch (methodName) {
    case "fetchQuery":
      return getFetchQuery(fn, path)
    case "fetchQueryOnce":
      return getFetchQueryOnce(fn, path)
    case "getQueryData":
      return getGetQueryData(path)
    case "invalidateQueries":
      return getInvalidateQueries(path)
    case "prefetchQuery":
      return getPrefetchQuery(fn, path)
    case "setQueriesData":
      return getSetQueriesData(path)
    case "setQueryData":
      return getSetQueryData(path)
    case "useInfiniteQuery":
      return getUseInfiniteQuery(fn, path)
    case "useMutation":
      return getUseMutation(fn)
    case "useQuery":
      return getUseQuery(fn, path)
    case "useQueries":
      return getUseQueries(fn, path)
  }
}
