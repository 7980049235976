import * as authApi from "auth"
import { API_BASE_URL, API_TOKEN, AUTH_URL, LOGIN_URL } from "src/env"

export const initializeAuth = () => {
  authApi.initializeAuth({
    authUrl: AUTH_URL,
    loginUrl: LOGIN_URL,
    backendUrl: API_BASE_URL,
    backendApiToken: API_TOKEN,
    subdomain: authApi.getSubdomainFromPath(),
  })
}
