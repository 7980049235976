import { getActiveSession } from "auth"
import { v3 } from "src/bonzai/bonzai"
import { getLocaleFromStorage } from "./localeStorage"
import { pickLocale } from "./pickLocale"

export const pickLocaleForCurrentCompany = async () => {
  const res = await v3.getCurrentCompanySettings.fetchQueryOnce()

  const { portal_supported_locales, default_locale } = res.data

  const { companyNameKey, pickedLocale } = await getActiveSession()

  const storedLocale = await getLocaleFromStorage(companyNameKey)
  const allowedLocales = portal_supported_locales.map((locale) => locale.code)

  if (storedLocale && allowedLocales.includes(storedLocale)) {
    return storedLocale
  }

  if (pickedLocale && allowedLocales.includes(pickedLocale)) {
    return pickedLocale
  }

  return pickLocale({
    defaultLocale: default_locale.code,
    browserLocales: window.navigator.languages,
    allowedLocales,
  })
}
