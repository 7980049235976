import { useSearch } from "@tanstack/react-location"
import { useQueryAll } from "bonzai"
import { useTranslation } from "react-i18next"
import { custom, v1 } from "src/bonzai/bonzai"
import { getFullName } from "src/dataUtilities/getFullName"
import { UserWidget } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type UserStatus = v1["getUserStatus"]

export const UserWidgetLoader = () => (
  <QueryBoundary fallback={<UserWidget.Skeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const { t } = useTranslation()

  const [me, userStatus, flags] = useData()

  return (
    <UserWidget>
      {!flags.disable_user_profile && (
        <UserWidget.User
          link={{ to: "/me" }}
          name={getFullName(me)}
          avatar={me.image}
          position={me.position ?? ""}
        />
      )}
      {!flags.disable_completed_courses_stars && (
        <UserWidget.Progress>
          <UserWidget.ProgressTooltip
            title={t("user.COMPLETED")}
            description={t("user.USER_INFO.completed")}
          >
            <UserWidget.Completion
              completedText={t("user.COMPLETED")}
              progress={userStatus.certified.count}
              progressMax={userStatus.certified.of}
            />
          </UserWidget.ProgressTooltip>
          <UserWidget.ProgressTooltip
            title={t("user.STARS")}
            description={t("user.USER_INFO.stars")}
          >
            <UserWidget.Stars
              stars={userStatus.stars.count}
              starsMax={userStatus.stars.of}
              starsText={t("user.STARS")}
            />
          </UserWidget.ProgressTooltip>
        </UserWidget.Progress>
      )}

      <UserWidget.Filters>
        {/* Wait until api is ready */}
        {/* <Filter variant="incomplete" status={userStatus} /> */}
        <Filter variant="mandatory" status={userStatus} />
      </UserWidget.Filters>
    </UserWidget>
  )
}

type FilterProps = {
  variant: "mandatory" | "incomplete"
  status: UserStatus
}
const Filter = ({ variant, status }: FilterProps) => {
  const { t } = useTranslation()

  const searchParams = useSearch()
  const linkParams = {
    ...searchParams,
    [variant]: searchParams[variant] ? undefined : true,
  }

  const progress =
    variant === "mandatory"
      ? getMandatoryProgress(status)
      : getIncompleteProgress(status)

  const text =
    variant === "mandatory" ? t("user.MANDATORY") : t("user.INCOMPLETE")

  const isVisible = variant === "mandatory" ? status.mandatory.of > 0 : true

  return (
    <UserWidget.Filter
      color={variant === "mandatory" ? "pink" : "blue"}
      progress={progress}
      text={text}
      isActive={Boolean(searchParams[variant])}
      link={{ to: "/for-you", search: linkParams }}
      isVisible={isVisible}
    />
  )
}

const useData = () => {
  return useQueryAll(() => [
    v1.getMe.useQuery(),

    v1.getUserStatus.useQuery({
      refetchOnWindowFocus: "always",
    }),

    custom.getPortalConfigurations.useQuery(),
  ])
}

const getMandatoryProgress = (status: UserStatus): string => {
  const mandatory = status.mandatory
  return `${mandatory.count}/${mandatory.of}`
}

const getIncompleteProgress = (status: UserStatus): string => {
  const certified = status.certified
  const incompleteProducts = certified.of - certified.count
  return `${incompleteProducts}`
}
