import { ReactComponent as ChevronIcon } from "assets/icons/chevronIcon.svg"
import crownIconGold from "assets/icons/crown.svg"
import crownIconBronse from "assets/icons/crownIconBronse.svg"
import crownIconSilver from "assets/icons/crownIconSilver.svg"
import { PropsWithChildren, ReactNode } from "react"
import { modifiers } from "ui-tools"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { Card } from "../../base/Card"
import s from "./LeaderboardViewCompact.module.scss"

export const LeaderboardViewCompact = ({ children }: PropsWithChildren) => (
  <div className={s.leaderboardViewCompact}>{children}</div>
)

type ScopeSelectProps = {
  value: string
  children: ReactNode
  onChange: (value: string) => void
}
const ScopeSelect = ({ children, value, onChange }: ScopeSelectProps) => (
  <select
    className={s.scopeSelect}
    value={value}
    onChange={(value) => onChange(value.target.value)}
  >
    {children}
  </select>
)

type ScopeProps = {
  value: string
  children: ReactNode
}
const Scope = ({ value, children }: ScopeProps) => (
  <option value={value}>{children}</option>
)

const Leaderboard = ({ children }: PropsWithChildren) => (
  <Card borderRadius="24">
    <div className={s.leaderboard}>{children}</div>
  </Card>
)

type HeaderProps = {
  title: string
  link: FiestaLinkProps
  linkText: string
}
const Header = ({ title, link, linkText }: HeaderProps) => (
  <div className={s.header}>
    <h5 className={s.header__title}>{title}</h5>
    <FiestaLink className={s.header__seeMore} {...link}>
      <span>{linkText}</span>
      <ChevronIcon />
    </FiestaLink>
  </div>
)

const Podium = ({ children }: PropsWithChildren) => (
  <div className={s.podium}>{children}</div>
)

type EntryPodiumProps = {
  rank: number
  avatar: string
  name: string
  score: string
  isFirst: boolean
}
const EntryPodium = ({
  rank,
  avatar,
  name,
  score,
  isFirst,
}: EntryPodiumProps) => (
  <div className={modifiers(s, "entryPodium", { isFirst })}>
    <img className={s.entryPodium__crown} src={getCrownIcon(rank)} alt="" />
    <div className={s.entryPodium__rankWrapper}>
      <div className={s.entryPodium__rank}>{rank}</div>
      <img src={avatar} className={s.entryPodium__avatar} alt="" />
    </div>
    <div className={s.entryPodium__name}>{name}</div>
    <div className={s.entryPodium__score}>{score}</div>
  </div>
)

type EntryProps = {
  rank: number
  avatar: string
  name: string
  score: string
  isMe: boolean
}
const Entry = ({ rank, avatar, name, score, isMe = false }: EntryProps) => (
  <div className={modifiers(s, "entry", { isMe })}>
    <div className={s.entry__rank}>{rank}</div>
    <img src={avatar} className={s.entry__avatar} alt="" />
    <div className={s.entry__name}>{name}</div>
    <div className={s.entry__score}>{score}</div>
  </div>
)

const Separator = () => <div className={s.separator} />

const Skeleton = () => (
  <Card>
    <div className={s.skeleton} />
  </Card>
)

LeaderboardViewCompact.ScopeSelect = ScopeSelect
LeaderboardViewCompact.Scope = Scope
LeaderboardViewCompact.Leaderboard = Leaderboard
LeaderboardViewCompact.Header = Header
LeaderboardViewCompact.Podium = Podium
LeaderboardViewCompact.EntryPodium = EntryPodium
LeaderboardViewCompact.Entry = Entry
LeaderboardViewCompact.Separator = Separator
LeaderboardViewCompact.Skeleton = Skeleton

const getCrownIcon = (rank: number) => {
  if (rank === 1) return crownIconGold
  if (rank === 2) return crownIconSilver
  if (rank === 3) return crownIconBronse
}
