import { exists } from "./exists"

type QueryParam = string | number | undefined | null | boolean
export type QueryParams = Record<string, QueryParam>

export const withQueryParams = (path: string, queryParams: QueryParams) => {
  const [safePath, initialParams] = splitPathAndParams(path)
  return safePath + generateQueryString(initialParams, queryParams)
}

const splitPathAndParams = (pathWithParams: string) => {
  const [path, ...rest] = pathWithParams.split("?")
  const params = rest.join("&")
  return [path, params] as const
}

const generateQueryString = (
  initialParams: string,
  queryParams: QueryParams
) => {
  const search = new URLSearchParams(initialParams)

  for (const [key, value] of Object.entries(queryParams)) {
    if (exists(value)) {
      search.set(key, String(value))
    }
  }

  const searchString = search.toString()
  if (searchString === "") return ""

  return "?" + searchString
}
