import { getEnvs } from "tools"

export const IS_DEV_BUILD = import.meta.env.DEV
export const IS_PROD_BUILD = import.meta.env.PROD
export const IS_TEST_BUILD = import.meta.env.MODE === "test"

export const {
  API_BASE_URL,
  API_TOKEN,
  AUTH_URL,
  LOGIN_URL,
  APP_ENV,
  SENTRY_URL,
  ADMIN_URL,
  VERCEL_GIT_COMMIT_SHA,
} = getEnvs([
  "VITE_API_TOKEN",
  "VITE_API_BASE_URL",
  "VITE_LOGIN_URL",
  "VITE_AUTH_URL",
  "VITE_APP_ENV",
  "VITE_SENTRY_URL",
  "VITE_ADMIN_URL",
  "VERCEL_GIT_COMMIT_SHA",
])
