/**
 * Some older browsers don't support blob.text(), so this is an older implementation
 */
export const blobToText = (data: Blob) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.addEventListener("load", (event) => {
      const result = event.target?.result

      if (typeof result !== "string") {
        reject("Target result isn't a string")
      } else {
        resolve(result)
      }
    })

    reader.readAsText(data)
  })
}
