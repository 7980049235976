import { useCallback, useState } from "react"

export const useToggle = (
  initialState = false
): [boolean, () => void, () => void, () => void] => {
  const [value, setValue] = useState(initialState)

  const toggleValue = useCallback(() => {
    setValue((isToggled) => !isToggled)
  }, [])

  const setFalse = useCallback(() => {
    setValue(false)
  }, [])

  const setTrue = useCallback(() => {
    setValue(true)
  }, [])

  return [value, toggleValue, setFalse, setTrue]
}
