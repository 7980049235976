import { withQueryParams } from "utils"

type OptimizationOptions = {
  width?: number
  height?: number
}

//https://docs.imgix.com/apis/rendering
type ImgixParams = {
  w?: number
  h?: number
  auto?: "format" | "format,compress"
  fit?: "max"
}

export const optimizeImage = (
  url: string,
  options: OptimizationOptions = {}
) => {
  // Disable imgix until we have a license
  return url
  if (!url) return ""

  const params: ImgixParams = {
    w: options.width,
    h: options.height,
    // auto: "format,compress",
    auto: "format",
    fit: "max",
  }
  return withQueryParams(replaceOrigin(url), params)
}

const replaceOrigin = (url: string) => {
  return url
    .replace(
      "https://webcontent.attensi.com",
      "https://attensi-webcontent.imgix.net"
    )
    .replace(
      "https://attensi-portal-production.s3.eu-central-1.amazonaws.com",
      "https://attensi-webcontent.imgix.net"
    )
    .replace(
      "https://backend.attensi.com",
      "https://attensi-backend-redirect.imgix.net"
    )
    .replace("products//", "products/%2F")
}
