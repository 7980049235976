import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from "@floating-ui/react"
import { useRef, useState } from "react"

/**
 * Docs for building tooltip with floating ui
 * https://floating-ui.com/docs/tooltip
 */

export type TooltipConfig = {
  position?: "top" | "bottom" | "left" | "right"
  theme?: "green" | "red" | "white" | "grey"
  initialIsVisible?: boolean
}

export const useTooltip = (config: TooltipConfig) => {
  const [isOpen, setIsOpen] = useState(config.initialIsVisible ?? false)

  const arrowRef = useRef(null)

  const { context, middlewareData, placement } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: config.position,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: "start" }),
      shift(),
      arrow({ element: arrowRef }),
    ],
  })

  const hover = useHover(context, { move: false })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: "tooltip" })

  const interactions = useInteractions([hover, focus, dismiss, role])
  const { getReferenceProps, getFloatingProps } = interactions

  const triggerProps = getReferenceProps({
    ref: context.refs.setReference,
  })

  const { isMounted, styles } = useTransitionStyles(context)

  const contentProps = getFloatingProps({
    ref: context.refs.setFloating,
    style: {
      position: context.strategy,
      top: context.y ?? 0,
      left: context.x ?? 0,
      width: "max-content",
      ...styles,
    },
  })

  const arrowProps = {
    ref: arrowRef,
    style: {
      top: middlewareData.arrow?.y,
      left: middlewareData.arrow?.x,
      right: placement === "left" ? 0 : undefined,
      bottom: placement === "top" ? 0 : undefined,
    },
  }

  const position = placement as TooltipConfig["position"]

  return { isMounted, triggerProps, contentProps, arrowProps, config, position }
}
