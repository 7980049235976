import { FetchQueryOptions } from "@tanstack/react-query"
import { queryClient } from "./queryClient"
import { FnBase, FnParams, FnReturn, NoArgFn } from "./types"
import { getArgs } from "./utils/getArgs"

type Options<Fn extends FnBase> = FetchQueryOptions<FnReturn<Fn>>
type Return<Fn extends FnBase> = Promise<FnReturn<Fn>>

export type FetchQuery<Fn extends FnBase> = Fn extends NoArgFn
  ? {
      (args?: FnParams<Fn>, options?: Options<Fn>): Return<Fn>
      (options?: Options<Fn>): Return<Fn>
    }
  : {
      (args: FnParams<Fn>, options?: Options<Fn>): Return<Fn>
    }

export const getFetchQuery = (
  fn: FnBase,
  path: string[]
): FetchQuery<FnBase> => {
  return (...input) => {
    const { args = [], rest } = getArgs(input)
    const key = [...path, ...args]
    return queryClient.fetchQuery(key, () => fn(...args), ...rest)
  }
}
