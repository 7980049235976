import { showErrorPopup } from "./showErrorPopup"

export const initializeGlobalErrorPopup = () => {
  window.addEventListener("error", ({ error }) => {
    showErrorPopup({ error })
  })

  window.addEventListener("unhandledrejection", ({ reason }) => {
    showErrorPopup({ error: reason })
  })
}
