import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { PropsWithChildren } from "react"
import { modifiers } from "ui-tools"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { ProgressBar } from "../../base/ProgressBar"
import { ProductCard } from "../ProductCard/ProductCard"
import s from "./ProductCardActivity.module.scss"

type ProductCardActivityProps = {
  link: FiestaLinkProps
  info: string
  title: string
  completedCount: number
  totalCount: number
  isCompleted: boolean
  isCertified: boolean
  isMandatory: boolean
  mandatoryText: string
}
export const ProductCardActivity = ({
  link,
  info,
  title,
  completedCount,
  totalCount,
  isCompleted,
  isMandatory,
  isCertified,
  mandatoryText,
}: ProductCardActivityProps) => (
  <ProductCard.Shell>
    <FiestaLink
      className={modifiers(s, "card", { isCompleted, isCertified })}
      {...link}
    >
      {isCertified && <Certified />}
      {isMandatory && <Mandatory>{mandatoryText}</Mandatory>}

      <div className={s.card__info}>{info}</div>
      <div className={s.card__progress}>
        {completedCount}/{totalCount}
        <ProgressBar
          value={completedCount}
          maxValue={totalCount}
          color={isCertified ? "green-30" : "blue-40"}
        />
      </div>
      <div className={s.card__title}>{title}</div>
      <div className={s.card__stars}>
        <Stars starsMax={1} stars={isCertified ? 1 : 0} />
      </div>
    </FiestaLink>
  </ProductCard.Shell>
)

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="" />
)

const Mandatory = ({ children }: PropsWithChildren) => (
  <div className={s.mandatory}>{children}</div>
)

type StarsProps = {
  stars: number
  starsMax: number
}
const Stars = ({ starsMax, stars }: StarsProps) => {
  const getSrc = (isFilled: boolean) => (isFilled ? starFilled : starEmpty)

  const starElements = times(starsMax, (index) => (
    <img src={getSrc(index < stars)} alt="" key={index} />
  ))

  return <div className={s.stars}>{starElements}</div>
}
